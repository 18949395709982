import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer";
import HeaderContext from "../../context/Context-header";
import Disclaimer from "../../components/Disclaimer";
import fetchData from "../../utils/WebService";
import SearchBox from "../../components/SearchBox/SearchBox";

export default function Layout({ component: Component }) {
  const [data, setData] = useState();
  useEffect(() => {
    const fetchDataAsync = async () => {
      const newData = await fetchData("xlri", "caution-statement");
      if (newData) {
        setData(newData);
      }
    };
    fetchDataAsync();
  }, []);

  return (
    <React.Fragment>
      {/* <ScrollingCursor /> */}
      {/* <Header /> */}

      <HeaderContext.Provider value={{ HeaderComponent: Header }}>
        <SearchBox />
        <main>
          <Component />
        </main>
        <Disclaimer data={data} />
      </HeaderContext.Provider>
      <Footer />
    </React.Fragment>
  );
}
