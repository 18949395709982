import { createContext, useState } from "react";

const SearchVisibilityContext = createContext();

export const SearchVisibilityProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <SearchVisibilityContext.Provider value={{ isVisible, setIsVisible }}>
      {children}
    </SearchVisibilityContext.Provider>
  );
};

export default SearchVisibilityContext;
