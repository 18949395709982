import React, { useContext, useState } from "react";
import styles from "./page.module.scss";
import pagenotfound from "../../assets/images/page-not-found/pagenotfoundimage.png";
import Footer from "../../components/Footer/index";
import Header from "../../components/Header/Header";
import HeaderContext from "../../context/Context-header";
import SearchVisibilityContext from "../../context/SearchBoxContext";
import SearchBox from "../../components/SearchBox/SearchBox";

const PageNotFound = () => {
  const { HeaderComponent } = useContext(HeaderContext);
  const [isVisible, setIsVisible] = useState(false);

  const handleBackToHome = () => {
    window.location.href = "/";
  };

  return (
    <SearchVisibilityContext.Provider value={{ isVisible, setIsVisible }}>
      <section>
        <Header />
        {/* <HeaderComponent /> */}
        <SearchBox />
        <div className={styles.pageContainer}>
          <div className={styles.imgWrapper}>
            <img src={pagenotfound} />
          </div>
          <div className={styles.contentWrapper}>
            <h6>404</h6>
            <p>Sorry, Page Not Found!</p>
            <button onClick={handleBackToHome}>BACK TO HOME</button>
          </div>
        </div>
        <Footer />
      </section>
    </SearchVisibilityContext.Provider>
  );
};

export default PageNotFound;
