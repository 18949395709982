import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./SearchBox.module.scss";
import { Searchpages } from "../../helpers/Search";
import { Link } from "react-router-dom";
import SearchVisibilityContext from "../../context/SearchBoxContext";

const SearchBox = () => {
  const [keyword, setKeyword] = useState("");
  const [filteredPages, setFilteredPages] = useState([]);
  const [loadingPages, setLoadingPages] = useState(false);
  const [position, setPosition] = useState("bottom");
  const [nothingFound, setNothingFound] = useState(false);

  const { isVisible, setIsVisible } = useContext(SearchVisibilityContext);
  const visibleRef = useRef();

  const onSearch = async (e) => {
    setLoadingPages(true);
    setKeyword(e);
    try {
      const results = await Searchpages(e);
      if (results?.length === 0) {
        setFilteredPages([]);
        setNothingFound(true);
      } else {
        setFilteredPages(results);
        setNothingFound(false);
      }
    } catch (error) {
      console.error("Error searching pages:", error);
    } finally {
      setLoadingPages(false);
    }
    if (e == "") {
      setNothingFound(false);
    }
  };

  const clearInput = () => {
    setKeyword("");
    setFilteredPages([]);
    setNothingFound(false);
  };

  const handleClickOutside = (event) => {
    if (visibleRef.current && !visibleRef.current.contains(event.target)) {
      setIsVisible(false);
      clearInput();
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      setIsVisible(false);
    }
  };

  const handleScroll = () => {
    if (window.innerHeight + window.scrollY > document.body.offsetHeight) {
      setPosition("top");
    } else if (window.screenY === 0) {
      setPosition("bottom");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleKeyDown);
      document.addEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    isVisible && (
      <div
        className={
          position == "bottom" ? `${styles.fixedWrapper}` : `${styles.top}`
        }
      >
        <div className="container">
          <div className={styles.searchWrapper}>
            <div className={styles.searchBox} ref={visibleRef}>
              <div className={styles.head}>
                <input
                  type="text"
                  value={keyword}
                  onChange={(e) => onSearch(e?.target?.value)}
                  placeholder="Search"
                  autoFocus
                />
                <button
                  type="button"
                  className="btn-close text-reset"
                  aria-label="Close"
                  onClick={() => clearInput()}
                ></button>
              </div>
              <div className={styles.list}>
                {loadingPages && <h6>Searching....</h6>}
                <ul>
                  <li className={styles.keySearches}>Key Searches</li>
                  {filteredPages?.length > 0 ? (
                    <>
                      {filteredPages?.map((obj, idx) => (
                        <li
                          onClick={() => {
                            setIsVisible(false);
                            clearInput();
                          }}
                        >
                          {obj?.route !== null ? (
                            <Link to={`${obj?.route}`}>
                              {obj?.highlightedTitle}
                            </Link>
                          ) : null}
                        </li>
                      ))}
                    </>
                  ) : (
                    nothingFound && <h6>No Pages Found!</h6>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default SearchBox;
