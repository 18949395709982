import pages from "../pages.json";
import fetchData from "../utils/WebService";

export const Searchpages = async (keyword) => {
  if (!keyword) return [];

  const lowerCaseKeyword = keyword.toLowerCase();

  try {
    // const [data1, data2, data3] = await Promise.all([
    //   fetchData("xlri", "long-term-courses"),
    //   fetchData("xlri", "short-term-courses"),
    //   fetchData("xlri", "conclave-seminars"),
    // ]);

    // const longTermCourses = createJsonData(
    //   data1,
    //   "ProgramName",
    //   "slug",
    //   "executive-education/vil/long-term-courses"
    // );
    // const shortTermCourses = createJsonData(
    //   data2,
    //   "ProgramName",
    //   "slug",
    //   "executive-education/vil/short-term-courses"
    // );
    // const conclaves = createJsonData(
    //   data3,
    //   "tabTitle",
    //   "group",
    //   "career-advisory-services/conclaves-seminars-panel-discussion"
    // );

    // const allPages = [
    //   ...pages,
    //   ...longTermCourses,
    //   ...shortTermCourses,
    //   ...conclaves,
    // ];

    return pages
      .filter((page) => page.title.toLowerCase().includes(lowerCaseKeyword))
      .map((page) => ({
        ...page,
        highlightedTitle: highlightKeyword(page.title, keyword),
      }));
  } catch (error) {
    console.error("Error fetching or searching pages:", error);
    return [];
  }
};

const highlightKeyword = (text, keyword) => {
  const parts = text.split(new RegExp(`(${keyword})`, "gi"));
  return parts.map((part, index) =>
    part.toLowerCase() === keyword.toLowerCase() ? (
      <span
        key={index}
        style={{ backgroundColor: "#d7eded", display: "inline" }}
      >
        {part}
      </span>
    ) : (
      part
    )
  );
};

const createJsonData = (data, titleKey, slugKey, routeKey) => {
  return data.map((item) => ({
    title: item.fields.find((field) => field.fieldName === titleKey)?.value,
    route: `/${routeKey}/${
      item.fields.find((field) => field.fieldName === slugKey)?.value
    }`,
  }));
};
