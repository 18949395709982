// homepage-banner-----------------
import logo from "../assets/images/header/logo.svg";
import closeBtn from "../assets/icons/close-btn.png";

import topheaderone from "../assets/images/header/topheaderone.png";
import topheadertwo from "../assets/images/header/topheadertwo.png";
import topheaderthree from "../assets/images/header/topheaderthree.png";
import HomePageBannerImage from "../assets/images/homepagebanner/banner-image.png";

import AnnouncementImage from "../assets/images/homepageannouncement/announcement.png";

import EventCardImage from "../assets/images/homepageevents/event-card.png";
import EventCardImage1 from "../assets/images/homepageevents/event-card1.png";
import EventCardImage2 from "../assets/images/homepageevents/event-card2.png";

import accreditionImgOne from "../assets/images/homepageaccredition/accredition1.png";
import accreditionImgTwo from "../assets/images/homepageaccredition/accredition2.png";
import accreditionImgThree from "../assets/images/homepageaccredition/accredition3.png";

import bannerVector from "../assets/images/homepageaccredition/background-vector.png";

import programcategoryimg from "../assets/images/homepageprogramcategories/programcategory.png";
import coparativerelation from "../assets/images/homepageprogramcategories/coparativerelationimg.png";
import mdprograme from "../assets/images/homepageprogramcategories/md-programmes.png";
import campusVisit from "../assets/images/homepageprogramcategories/slid4.jpg";
import studentEngagement from "../assets/images/homepageprogramcategories/slid2.jpg";
import leadershipSpeaker from "../assets/images/homepageprogramcategories/slid1.jpg";
import studentClubEvents from "../assets/images/homepageprogramcategories/slid3.jpg";
import companyprogrammes from "../assets/images/homepageprogramcategories/company-programmes.png";
import consultancyservices from "../assets/images/homepageprogramcategories/consultancy-services.png";
import virtualinteractivelearning from "../assets/images/homepageprogramcategories/virtual-interactive-learning.png";

import videopopupthumb from "../assets/images/homepagefeaturedvideo/thumb.png";
import videoThumbnail1 from "../assets/images/homepagefeaturedvideo/videoThumbnail1.jpg";
import videoThumbnail2 from "../assets/images/homepagefeaturedvideo/videoThumbnail2.jpg";
import videoThumbnail3 from "../assets/images/homepagefeaturedvideo/videoThumbnail3.jpg";
import videopopup from "../assets/images/homepagefeaturedvideo/vdoimg2.png";

import CultureTabImg from "../assets/images/Homepageculture/culturetab.png";
import CultureTabImg1 from "../assets/images/Homepageculture/culturetab1.png";
import CultureTabImg2 from "../assets/images/Homepageculture/culturetab2.png";

import accordionImg from "../assets/images/homepagefurther/accrdionImage.png";

import studentimg from "../assets/images/studenttestimonials/profile.jpg";
import studentimg1 from "../assets/images/studenttestimonials/student1.png";
import quoteimg from "../assets/images/homepagestudenttestimonials/quote.png";

import whatsappqr from "../assets/images/footer/xlri-qr.png";

import furthertitleone from "../assets/images/homepagefurther/title1.png";

import furthertitltwo from "../assets/images/homepagefurther/title2.png";

// -----about---us--------------

import aboutusbannerimg from "../assets/images/aboutus/banner/banner-img.jpg";

import aboutvisioncardimg from "../assets/images/aboutus/vision/cardone.svg";
import aboutvisioncardimg1 from "../assets/images/aboutus/vision/icon1.png";
import aboutvisioncardimg2 from "../assets/images/aboutus/vision/icon2.png";
import aboutvisioncardimg3 from "../assets/images/aboutus/vision/icon3.png";

import aboutuscard from "../assets/images/aboutus/cards/cardimg.png";
import aboutuscard1 from "../assets/images/aboutus/cards/cardimg1.png";
import aboutuscard2 from "../assets/images/aboutus/cards/cardimg2.png";
import aboutuscard3 from "../assets/images/aboutus/cards/cardimg3.png";
import aboutuscard4 from "../assets/images/aboutus/cards/cardimg4.png";
import aboutuscard5 from "../assets/images/aboutus/cards/cardimg5.png";

import aboutmegamenuimg from "../assets/images/aboutusmegamenu/main.png";

//  ---------placement commiteee --------------
import FacultyImg1 from "../assets/images/placement-committee/faculity-1.png";
import FacultyImg2 from "../assets/images/placement-committee/faculity-2.png";
import MemberImg1 from "../assets/images/placement-committee/members-1.png";
import MemberImg2 from "../assets/images/placement-committee/members-2.png";
import MemberImg3 from "../assets/images/placement-committee/members-3.png";
import MemberImg4 from "../assets/images/placement-committee/members-4.png";
import MemberCoordinatorImg from "../assets/images/placement-committee/coordinator-img.png";
import HeadMemberImg from "../assets/images/placement-committee/head-member.png";

//  ---------home banners --------------
import IsepHomePageBannerImage from "../assets/images/isep/isep-banner.png";
import AddmissionBannerImage from "../assets/images/addmission/long-course-banner.png";
import AddmissionShortImage from "../assets/images/addmission/short-course-banner.png";

//  ---------Alumni Imgs --------------
import AlumniMeet1 from "../assets/images/annual-alumni-meet/meet-img-1.png";
import AlumniMeet2 from "../assets/images/annual-alumni-meet/meet-img-2.png";
import AlumniMeet3 from "../assets/images/annual-alumni-meet/meet-img-3.png";
import AlumniMeet4 from "../assets/images/annual-alumni-meet/confluence-4.jpg";

// ---------Student Committee-----------
import placementCommittee from "../assets/images/student-committe/PLACECOM.jpg";
import CareerDevCommittee from "../assets/images/student-committe/CDC - CAREER DEVELOPMENT COMMITTEE.jpg";
import AlumniCommittee from "../assets/images/student-committe/Alumcomm.jpg";
import BrandCommittee from "../assets/images/student-committe/BRANDCOM.jpg";
import AcademicCommittee from "../assets/images/student-committe/CR.jpg";
import CEASE from "../assets/images/student-committe/CEASE.jpg";
import CultCommitte from "../assets/images/student-committe/Cult Comm.jpg";
import SportsCommitte from "../assets/images/student-committe/Sports Comm.jpg";
import InfraCommitte from "../assets/images/student-committe/Infracomm.jpg";
import CorpWorkCommitte from "../assets/images/student-committe/CWC - Corporate Workshop Committee.jpg";
import SocialMediaTeam from "../assets/images/student-committe/SMT - Social Media Team.jpg";
import ProfMentProgram from "../assets/images/student-committe/PMP.jpg";
import CorpCompCommitte from "../assets/images/student-committe/CCC - Corporate Competition Committee.jpg";
import conClave from "../assets/images/student-committe/CC - Conclave Committee.jpg";
import GuestSpeaker from "../assets/images/student-committe/GST - Guest Speakers Team.jpg";
import CorpProjectComm from "../assets/images/student-committe/Corporate-Projects-Committee---2022.jpg";
import Auracia from "../assets/images/student-committe/AURACIA.jpg";
// import Innov8 from '../assets/images/student-committe/E CELL.jpg'
import Kautilya from "../assets/images/student-committe/KCC - KAUTILYA CONSULTING COMMITTEE.jpg";

// ----------------isep-home---------------

import albaupper from "../assets/images/isep-home/albaupper-logo.png";
import amsLogo from "../assets/images/isep-home/ams-logo.png";
import asianInstitute from "../assets/images/isep-home/asian_institute.png";
import astonLogo from "../assets/images/isep-home/aston-university.png";
import audenciaLogo from "../assets/images/isep-home/audencia-logo.png";
import fordhamLogo from "../assets/images/isep-home/Fordham-University-logo.png";
import grenobleLogo from "../assets/images/isep-home/grenoble-logo.png";
import iajuLogo from "../assets/images/isep-home/iaju-logo.png";
import lboro from "../assets/images/isep-home/lboro-logo.png";
import emlyonLogo from "../assets/images/isep-home/logo_emlyon.png";
import iesegLogo from "../assets/images/isep-home/logo_ieseg_biseaute.png";
import exceliaLogo from "../assets/images/isep-home/logo-excelia-blanc.png";
import esadeLogo from "../assets/images/isep-home/LogoEsade-EN.png";
import lucLogo from "../assets/images/isep-home/luc-logo.png";
import macquarie from "../assets/images/isep-home/macquarie-logo.png";
import mduLogo from "../assets/images/isep-home/MDU-logo.png";
import misiLogo from "../assets/images/isep-home/misi-university-logo.png";
import neomaLogo from "../assets/images/isep-home/neoma-logo.png";
import rutgersLogo from "../assets/images/isep-home/rutgers-logo.png";
import solBridgeLogo from "../assets/images/isep-home/SolBridgeLogo.png";
import UgLogo from "../assets/images/isep-home/UG-logo.png";
import wvuLogo from "../assets/images/isep-home/wvu-logo.png";
import wwuLogo from "../assets/images/isep-home/wwu-logo.png";

import headerAbout from "../assets/images/header/Abouts.png";
import headerAcademic from "../assets/images/header/Academic Programmes.png";
import headerExecutive from "../assets/images/header/Executive Education.png";
import headerFaculty from "../assets/images/header/Faculty & Research.png";
import headerResource from "../assets/images/header/Resources.png";
import headerCareer from "../assets/images/header/Careers Advisory Services.png";
import headerCentres from "../assets/images/header/Centres.png";
import headerNews from "../assets/images/header/News.png";
import headerGivingXlri from "../assets/images/header/Group 70434.png";
import profilePicIcon from "../assets/images/firebase/profile.jpg";
import   AnnouncementImage2 from "../assets/images/homepageannouncement/announcements.png"
import  EventsCalenderImg from '../assets/images/events-calender/EventsCalendar.png'

const Assets = {
  aboutmegamenuimg,
  aboutuscard,
  aboutuscard1,
  aboutuscard2,
  aboutuscard3,
  aboutuscard4,
  aboutuscard5,
  aboutvisioncardimg,
  aboutvisioncardimg1,
  aboutvisioncardimg2,
  aboutvisioncardimg3,
  aboutusbannerimg,
  furthertitleone,
  furthertitltwo,
  topheaderone,
  topheadertwo,
  topheaderthree,
  logo,
  whatsappqr,
  quoteimg,
  studentimg,
  studentimg1,
  accordionImg,
  CultureTabImg,
  CultureTabImg1,
  CultureTabImg2,
  videopopupthumb,
  videopopup,
  programcategoryimg,
  coparativerelation,
  mdprograme,
  companyprogrammes,
  consultancyservices,
  virtualinteractivelearning,
  bannerVector,
  accreditionImgOne,
  accreditionImgTwo,
  accreditionImgThree,
  AnnouncementImage,
  HomePageBannerImage,
  EventCardImage,
  EventCardImage1,
  EventCardImage2,
  FacultyImg1,
  FacultyImg2,
  MemberImg1,
  MemberImg2,
  MemberImg3,
  MemberImg4,
  MemberCoordinatorImg,
  HeadMemberImg,
  IsepHomePageBannerImage,
  AddmissionBannerImage,
  AddmissionShortImage,
  AlumniMeet1,
  AlumniMeet2,
  AlumniMeet3,
  AlumniMeet4,
  campusVisit,
  leadershipSpeaker,
  studentEngagement,
  studentClubEvents,
  placementCommittee,
  CareerDevCommittee,
  AlumniCommittee,
  BrandCommittee,
  AcademicCommittee,
  CEASE,
  CultCommitte,
  SportsCommitte,
  InfraCommitte,
  CorpWorkCommitte,
  SocialMediaTeam,
  ProfMentProgram,
  CorpCompCommitte,
  conClave,
  GuestSpeaker,
  CorpCompCommitte,
  conClave,
  GuestSpeaker,
  CorpProjectComm,
  Auracia,
  // Innov8,
  Kautilya,
  videoThumbnail1,
  videoThumbnail2,
  videoThumbnail3,
  albaupper,
  amsLogo,
  asianInstitute,
  astonLogo,
  audenciaLogo,
  fordhamLogo,
  grenobleLogo,
  iajuLogo,
  lboro,
  emlyonLogo,
  iesegLogo,
  exceliaLogo,
  esadeLogo,
  lucLogo,
  macquarie,
  mduLogo,
  misiLogo,
  neomaLogo,
  rutgersLogo,
  solBridgeLogo,
  UgLogo,
  wvuLogo,
  wwuLogo,
  headerAbout,
  headerAcademic,
  headerExecutive,
  headerFaculty,
  headerResource,
  headerCareer,
  headerCentres,
  headerNews,
  headerGivingXlri,
  profilePicIcon,
  closeBtn,
  AnnouncementImage2,
  EventsCalenderImg
};

export default Assets;
