import { ApolloLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";

export const directionalLink = (token, uid) =>
  new ApolloLink.split(
    (operation) => operation.getContext().clientName === "case",
    new createUploadLink({
      uri: process.env.REACT_APP_GRAPHQL_CASE_CENTER,
      headers: token
        ? {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
            uuid: uid,
            "Access-Control-Allow-Origin": "*",
            "Cross-Origin-Embedder-Policy": "require-corp",
            "Cross-Origin-Opener-Policy": "same-origin",
          }
        : {},
    }),
    new ApolloLink.split(
      (operation) => operation.getContext().clientName === "cms",
      new createUploadLink({
        uri: process.env.REACT_APP_GRAPHQL_CMS,
        headers: token
          ? {
              Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
              uuid: uid,
              "Access-Control-Allow-Origin": "*",
              "Cross-Origin-Embedder-Policy": "require-corp",
              "Cross-Origin-Opener-Policy": "same-origin",
            }
          : {},
      }),
      new ApolloLink.split(
        (operation) => operation.getContext().clientName === "hrms",
        new createUploadLink({
          uri: process.env.REACT_APP_GRAPHQL_HRMS,
          headers: token
            ? {
                Authorization: `Bearer ${token}`,
                uuid: uid,
                "Access-Control-Allow-Origin": "*",
                "Cross-Origin-Embedder-Policy": "require-corp",
                "Cross-Origin-Opener-Policy": "same-origin",
              }
            : {},
        }),
        new ApolloLink.split(
          (operation) => operation.getContext().clientName === "utility",
          new createUploadLink({
            uri: process.env.REACT_APP_GRAPHQL_UTILITY_OPEN,
            headers: token
              ? {
                  Authorization: `Bearer ${token}`,
                  uuid: uid,
                  "Access-Control-Allow-Origin": "*",
                  "Cross-Origin-Embedder-Policy": "require-corp",
                  "Cross-Origin-Opener-Policy": "same-origin",
                }
              : {},
          }),
          new createUploadLink({
            uri: process.env.REACT_APP_GRAPHQL_ADMIN,
            headers: token
              ? {
                  Authorization: `Bearer ${token}`,
                  uuid: uid,
                  "Access-Control-Allow-Origin": "*",
                  "Cross-Origin-Embedder-Policy": "require-corp",
                  "Cross-Origin-Opener-Policy": "same-origin",
                }
              : {},
          })
        )
      )
    )
  );

export const errorLink = (onForbidden) =>
  onError(({ graphQLErrors }) => {
    if (graphQLErrors?.length) {
      graphQLErrors.forEach(({ message, extensions }) => {
        if (extensions?.code === "UNAUTHENTICATED") onForbidden(message);
      });
    }
  });
