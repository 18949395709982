import axios from "axios";

const fetchData = async (grpname, tmpid) => {
  const api = process.env.REACT_APP_API;
  const url = `${api}/${grpname}/${tmpid}`;
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export default fetchData;
