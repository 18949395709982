import { createContext, useEffect, useState } from "react";
import fetchData from "../utils/WebService";

const MetaDataContext = createContext();

export const MetaDataProvider = ({ children }) => {
  const [data, setData] = useState();

  useEffect(() => {
    const fetchMetaData = async () => {
      const newData = await fetchData("xlri", "meta-data");
      if (newData) {
        const newArray = newData.map((item) => {
          const fields = item.fields.reduce((acc, field) => {
            acc[field.fieldName] = field.value;
            return acc;
          }, {});

          return { _id: item._id, ...fields };
        });
        setData(newArray);
      }
    };
    fetchMetaData();
  }, []);

  return (
    <MetaDataContext.Provider value={data}>{children}</MetaDataContext.Provider>
  );
};

export default MetaDataContext;
