import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import relatedComponent from "./RelatedCourses.module.scss";
import Icon from "../../Layout/icons/icons";
import relatedImg1 from "../../assets/images/relatedcourse/related-course-img1.png";
import relatedImg2 from "../../assets/images/relatedcourse/related-course-img2.png";
import relatedImg3 from "../../assets/images/relatedcourse/related-course-img3.png";
import { Link } from "react-router-dom";

export default function RelatedCourses() {
  return (
    <section className={`section-blue ${relatedComponent.relatedComponentBg}`}>
      <div className="container">
        <h3>Related Courses</h3>
        <div className={relatedComponent.programcategorySliderWrap}>
          <Swiper
            slidesPerView={2}
            spaceBetween={30}
            navigation={{
              nextEl: ".slider-next",
              prevEl: ".slider-prev",
            }}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
            modules={[Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              <Link
                to="/academic-programmes/school-of-hrm/pgdm-hrm"
                className={relatedComponent.categorySlider}
              >
                <figure>
                  <img src={relatedImg1} alt=" Related Course" />
                </figure>
                <figcaption>
                  <h5 className="h5">PGDM (HRM)</h5>
                  <Icon icon="link-arrow" size={12} color="#000" />
                </figcaption>
              </Link>
            </SwiperSlide>
            <SwiperSlide>
              <Link
                to="/academic-programmes/school-of-business/pgdbm"
                className={relatedComponent.categorySlider}
              >
                <figure>
                  <img src={relatedImg2} alt=" Related Course" />
                </figure>
                <figcaption>
                  <h5 className="h5">PGDM (BM)</h5>
                  <Icon icon="link-arrow" size={12} color="#000" />
                </figcaption>
              </Link>
            </SwiperSlide>
            <SwiperSlide>
              <Link
                to="/academic-programmes/school-of-business/pgdgm"
                className={relatedComponent.categorySlider}
              >
                <figure>
                  <img src={relatedImg3} alt=" Related Course" />
                </figure>
                <figcaption>
                  <h5 className="h5">PGDM (GM)</h5>
                  <Icon icon="link-arrow" size={12} color="#000" />
                </figcaption>
              </Link>
            </SwiperSlide>
            <SwiperSlide>
              <Link
                to="/academic-programmes/school-of-business/pgdmiev"
                className={relatedComponent.categorySlider}
              >
                <figure>
                  <img src={relatedImg1} alt=" Related Course" />
                </figure>
                <figcaption>
                  <h5 className="h5">PGDM (IEV)</h5>
                  <Icon icon="link-arrow" size={12} color="#000" />
                </figcaption>
              </Link>
            </SwiperSlide>
            <SwiperSlide>
              <Link
                to="/lscm"
                className={relatedComponent.categorySlider}
              >
                <figure>
                  <img src={relatedImg2} alt=" Related Course" />
                </figure>
                <figcaption>
                  <h5 className="h5">PGDM (LSCM)</h5>
                  <Icon icon="link-arrow" size={12} color="#000" />
                </figcaption>
              </Link>
            </SwiperSlide>
          </Swiper>
          <div className={relatedComponent.swiperBtnWrapper}>
            <button className="swiper-arrows-green-fill swiper-arrows-green-fill-prev slider-prev">
              <Icon icon="slider-arrow" size={21} color="#000000" />
            </button>
            <button className="swiper-arrows-green-fill swiper-arrows-green-fill-next slider-next">
              <Icon icon="slider-arrow" size={21} color="#000000" />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}
