import { createSlice } from "@reduxjs/toolkit";

export const cacheSlice = createSlice({
  name: "cache",
  initialState: {
    apollo: null,
  },
  reducers: {
    setApolloClient: (state, action) => {
      return { apollo: action.payload };
    },
  },
});

export const { setApolloClient } = cacheSlice.actions;

export default cacheSlice.reducer;
