import styles from "./disclaimer.module.scss";
import { useEffect, useState } from "react";
export default function Disclaimer(props) {
  const [view, setView] = useState(true);
  const [caution, setCaution] = useState();

  useEffect(() => {
    setCaution(props?.data);
  }, [props]);

  return (
    <>{
      caution?.show?.includes("true") ? (
        <>
        {view && (
          <div className={styles.desclaimerContainer}>
            <p dangerouslySetInnerHTML={{ __html: caution?.content }}></p>
            <button onClick={() => setView(false)} className={styles.btn}>
              X
            </button>
          </div>
        )}
      </>
      ) :(<></>)
    }
    </>
  );
}
