import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const MetaData = ({ metaData, courses }) => {
  const location = useLocation();
  const [item, setItem] = useState();

  useEffect(() => {
    const filtered = metaData?.find(
      (obj) => obj?.pageRoute === location?.pathname
    );
    if (filtered) {
      setItem(filtered);
    }
  }, [location, metaData]);
  

  return (
    <div>
      <Helmet>
        <title>
          {item?.metaTitle
            ? item?.metaTitle
            : courses?.[0]?.ProgramName
            ? courses?.[0]?.ProgramName
            : "Xlri - An institution Nurturing Responsible Leaders"}
        </title>
        <meta
          name="description"
          content={
            item?.metaDescription
              ? item?.metaDescription
              : courses?.[0]?.ShortDescription
              ? courses?.[0]?.ShortDescription
              : "XLRI is the oldest management school in India. We aim to be an institution of excellence nurturing responsible global leaders."
          }
        />
        <meta name="keywords" content={item?.keywords || "XLRI"} />
      </Helmet>
    </div>
  );
};

export default MetaData;
