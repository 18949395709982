import { createSlice } from "@reduxjs/toolkit";
import { setToken } from "../getToken";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: false,
    userData: null,
    uid: null,
    loading: false,
    userDetails: null,
    studentProfileIndex: null,
    studentProfileFilters: null,
    studentProfileFilteredData: null,
  },
  reducers: {
    loginUser: (state, action) => {
      setToken(action.payload);
      return {
        ...state,
        isAuthenticated: true,
        userData: action.payload.token,
        uid: action.payload.uid,
        loading: false,
      };
    },
    logoutUser: (state, action) => {
      if (action.payload?.cache) action.payload.clearStore();
      setToken();
      return {
        isAuthenticated: false,
        userData: null,
        uid: null,
        userDetails: null,
      };
    },
    setUserData: (state, action) => {
      return {
        ...state,
        userDetails: action.payload,
      };
    },
    setForgotPassword: (state, action) => {
      return {
        ...state,
        loading: true,
        forgotPassword: false,
      };
    },
    setForgotPasswordSuccess: (state, action) => {
      return {
        ...state,
        loading: false,
        forgotPassword: true,
      };
    },
    setForgotPasswordError: (state, action) => {
      return {
        ...state,
        loading: false,
        forgotPassword: action.payload,
      };
    },
    studentProfileNav: (state, action) => {
      return {
        ...state,
        studentProfileIndex: action.payload,
      };
    },
    studentProfileFiltersBm: (state, action) => {
      return {
        ...state,
        studentProfileFilters: action.payload,
      };
    },
    studentProfileFilteredDataBm: (state, action) => {
      return {
        ...state,
        studentProfileFilteredData: action.payload,
      };
    },
  },
});
export const {
  loginUser,
  logoutUser,
  setUserData,
  setForgotPassword,
  setForgotPasswordSuccess,
  setForgotPasswordError,
  studentProfileNav,
  studentProfileFiltersBm,
  studentProfileFilteredDataBm,
} = authSlice.actions;
export default authSlice.reducer;
