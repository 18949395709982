import { gql } from "@apollo/client";
import React from "react";

export const MAIN_SEARCH = gql`
  query allCMSData($search: String!) {
    allCMSData(search: $search) {
      _id
      page
      slug
      templateId
      groupName
      groupId
      content {
        _id
        title
        fields {
          _id
          fieldName
          type
          value
        }
        status
      }
      fields {
        _id
        fieldName
        type
        value
      }
      members {
        _id
      }
      membersData {
        _id
        firstName
        lastName
        email
        phone
        picUrl
        fcmToken
        userType
        otherRoles
        status
        createdAt
        updatedAt
      }
      created {
        author
        picUrl
        date
        _id
      }
      modified {
        author
        picUrl
        date
        _id
      }
      cmsType
      status
    }
  }
`;

