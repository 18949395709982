import React, { useContext, useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import Accordion from "react-bootstrap/Accordion";
import styles from "./Header.module.scss";
import Assets from "../../Layout/assets";
import Icon from "../../Layout/icons/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import menuIcon from "../../assets/icons/tooglemenu.png";
import { MAIN_SEARCH } from "../../utils/search";
import { useLazyQuery } from "@apollo/client";
import subStyles from "./subHeader.module.scss";
import fetchData from "../../utils/WebService";
import { Searchpages } from "../../helpers/Search";
import SearchBox from "../SearchBox/SearchBox";
import SearchVisibilityContext from "../../context/SearchBoxContext";
import { useDispatch } from "react-redux";
import { studentProfileNav } from "../../utils/redux/auth";

export default function Header() {
  const { setIsVisible } = useContext(SearchVisibilityContext);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrolledState, setScrolledState] = useState(undefined);
  const [lastScroll, setLastScroll] = useState(0);
  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
    return position;
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    if (scrollPosition > 150) {
      if (scrollPosition > lastScroll) {
        setScrolledState(true);
      } else if (scrollPosition == lastScroll) {
        setScrollPosition(false);
      } else {
        setScrolledState(false);
      }
    }
    setLastScroll(scrollPosition);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [window.scrollY]);

  const marqueeRef = useRef();

  const handleStop = () => {
    marqueeRef.current.stop();
  };

  const handleStart = () => {
    marqueeRef.current.start();
  };

  const navigate = useNavigate();

  const redirectToSection = (url, key) => {
    navigate(`${url}`, { state: { key: `${key}` } });
  };

  const location = useLocation();
  const pathname = location.pathname;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!location?.pathname?.includes("/student-profile-pgdm-gm")) {
      dispatch(studentProfileNav(null));
    }
  }, [pathname]);

  const [notifications, setNotifications] = useState([]);
  const [prospectus, setProspectus] = useState();
  const [prospectusLscm, setProspectusLscm] = useState();
  const [isLoading, setIsloading] = useState(false);
  const [prospectusBm, setProspectusBm] = useState();

  const searchRef = useRef();
  const [keyword, setKeyword] = useState("");
  const [filteredPages, setFilteredPages] = useState([]);
  const [loadingPages, setLoadingPages] = useState(false);

  const [searchItems, { loading, data }] = useLazyQuery(MAIN_SEARCH);

  // const onSearch = (e) => {
  //   searchItems({
  //     variables: {
  //       search: e.target?.value,
  //     },
  //     context: {
  //       clientName: "cms",
  //     },
  //   });
  // };

  const onSearch = async (e) => {
    setLoadingPages(true);
    setKeyword(e);
    const results = await Searchpages(e);
    if (results) {
      setFilteredPages(results);
      setLoadingPages(false);
    }
  };

  useEffect(() => {
    const fetchDataAsync = async () => {
      setIsloading(true);
      const newData = await fetchData("xlri", "notifications");
      if (newData) {
        const newArray = newData.map((item) => {
          const fields = item.fields.reduce((acc, field) => {
            acc[field.fieldName] = field.value;
            return acc;
          }, {});

          return { _id: item._id, ...fields };
        });
        setNotifications(newArray);
        setIsloading(false);
      }
    };
    fetchDataAsync();
  }, []);

  useEffect(() => {
    const fetchProspectus = async () => {
      const newData = await fetchData("xlri", "xat-prospectus");
      setProspectus(newData);
      const newData3 = await fetchData("xlri", "brochure-pgdm-gm");
      setProspectusBm(newData3);
    };
    const fetchBrochure = async () => {
      const newData2 = await fetchData("xlri", "double-masters");
      setProspectusLscm(newData2);
    };
    fetchProspectus();
    fetchBrochure();
  }, []);

  return (
    <>
      <header
        className={`${styles.headerWrapper} ${
          scrolledState == true || scrolledState == false
            ? scrolledState
              ? styles.displayNone
              : styles.displayTrue
            : ""
        } `}
      >
        <div className={styles.moreBtnMaindivAll}>
          <div
            className="offcanvas offcanvas-end"
            tabIndex="-1"
            id="offcanvasRight"
            aria-labelledby="offcanvasRightLabel"
          >
            <div className="offcanvas-header">
              <button
                type="button"
                className="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <ul>
                <li>
                  <Link to="https://xlridelhi.ac.in/#/" target={"_blank"}>
                    XLRI Delhi-NCR
                  </Link>{" "}
                </li>

                <li>
                  <Link to="/alumni">Alumni Association</Link>{" "}
                </li>
                <li>
                  <Link to="/current-student/student-societies">
                    {" "}
                    Current Students
                  </Link>{" "}
                </li>
                <li>
                  <Link to="/5s">5 s</Link>
                </li>
                <li>
                  <Link to="/contact"> Contact us</Link>{" "}
                </li>
                <li>
                  <Link to="/faq"> FAQs</Link>{" "}
                </li>
                <li>
                  <Link to="/reaching-xlri">Reaching XLRI</Link>{" "}
                </li>
                <li>
                  <Link to="/contact-online-grievance">Online Grievance</Link>{" "}
                  {/* <Link to="https://acad.xlri.ac.in/grievance/">
                    Online Grievance
                  </Link>{" "} */}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.serchWrapperBodymain}>
          <div
            className="offcanvas offcanvas-top "
            tabIndex="-1"
            id="offcanvasTop"
            aria-labelledby="offcanvasTopLabel"
          >
            <div className="offcanvas-body">
              <div className={styles.searchWrapperMain}>
                <div className={styles.searchWrapperMainDiv2}>
                  <Link to="/">
                    <Icon icon="search" size={18} color="#000000" />
                  </Link>
                </div>
                <input
                  placeholder="Search Here"
                  type="text"
                  value={keyword}
                  ref={searchRef}
                  onChange={(e) => onSearch(e?.target?.value)}
                ></input>
                <div className={styles.searchWrapperMainDiv1}>
                  <button
                    type="button"
                    className="btn-close text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>

                {loadingPages ? (
                  <h6>Searching...</h6>
                ) : (
                  <div className={styles.searchareaDiv}>
                    {filteredPages ? (
                      <div>
                        <span>Key searches</span>
                        <ul>
                          {filteredPages?.map((item) => (
                            <li>
                              {item.route !== null ? (
                                <Link to={`${item.route}`}>
                                  {item?.highlightedTitle}
                                </Link>
                              ) : null}
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <>Nothing Found!</>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.HeaderSection1}>
          <div className="container">
            <div className={styles.mobileMainMenu}>
              <div
                className="offcanvas offcanvas-end"
                tabIndex="-1"
                id="offcanvasExample"
                aria-labelledby="offcanvasExampleLabel"
              >
                <div className="offcanvas-header">
                  <button
                    type="button"
                    className="btn-close text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="offcanvas-body">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="mainMenuaccordionBorder">
                        <Link to="/about"> About</Link>{" "}
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            <Link to="/about/director-message">
                              Director's Message{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="/about/about-the-director">
                              About The Director
                            </Link>
                          </li>
                          <li>
                            <Link to="/about/heritage">Heritage</Link>
                          </li>
                          <li>
                            <Link to="/about/jesuit-founding-fathers">
                              Founding Fathers
                            </Link>
                          </li>
                          <li>
                            <Link to="/about/our-brand-identity">
                              Brand XLRI
                            </Link>
                          </li>
                          <li>
                            <Link to="/about/vision-mission">
                              Vision and Mission
                            </Link>
                          </li>
                          <li>
                            <Link to="/about/board-of-governors">
                              Board of Governors
                            </Link>
                          </li>
                          <li>
                            <Link to="/about/leadership-administration">
                              Leadership and Administration
                            </Link>
                          </li>
                          <li>
                            <Link to="/about/industry-advisory-councils">
                              Industry Advisory Council
                            </Link>
                          </li>
                          <li>
                            <Link to="/about/programs-and-equivalence">
                              Programs and Equivalence
                            </Link>
                          </li>
                          <li>
                            <Link to="/about/xlri-ranking">
                              XLRI’s Position & Ranking
                            </Link>
                          </li>
                          <li>
                            <Link to="/newsroom/accreditations">
                              Accreditation
                            </Link>
                          </li>
                          <li>
                            <Link to="/about/social-initiatives">
                              Social Initiatives
                            </Link>
                          </li>
                          <li>
                            <Link to="/about/social-engagement-activities">
                              Social Engagement Activities
                            </Link>
                          </li>
                          <li>
                            <Link to="/about/mandatory-disclosure">
                              Mandatory Disclosure
                            </Link>
                          </li>
                          <li>
                            <Link to="/about/careers-opportunities">
                              Careers Opportunities
                            </Link>
                          </li>
                        </ul>
                        <div className={styles.innerDropdownMain}>
                          <Accordion>
                            <Accordion.Item eventKey="20">
                              <Accordion.Header>Faculty</Accordion.Header>
                              <Accordion.Body>
                                <ul>
                                  <li>
                                    <Link to="/about/full-time-faculty">
                                      Full Time Faculty
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/about/visiting-faculty">
                                      Visiting Faculty
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/about/adjunct-faculty">
                                      Adjunct Faculty
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/about/reserach-faculty-visiting">
                                      Research Faculty (Visiting)
                                    </Link>
                                  </li>
                                </ul>
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="21">
                              <Accordion.Header>Support Staff</Accordion.Header>
                              <Accordion.Body>
                                <ul>
                                  <li>
                                    <Link to="/support-staff/administrative">
                                      Administrative
                                    </Link>{" "}
                                  </li>
                                  <li>
                                    <Link to="/support-staff/teaching-assistants">
                                      Teaching Assistants
                                    </Link>
                                  </li>
                                  {/* <li>
                                    <Link to="/support-staff/research-associate">
                                      Research Associates
                                    </Link>
                                  </li> */}
                                </ul>
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="22">
                              <Accordion.Header>
                                International Collaboration
                              </Accordion.Header>
                              <Accordion.Body>
                                <ul>
                                  <li>
                                    <Link to="/isep">ISEP</Link>{" "}
                                  </li>
                                </ul>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        <Link to="/academic-programmes">
                          Academic Programmes{" "}
                        </Link>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className={styles.innerDropdownMain}>
                          <Accordion>
                            <Accordion.Item eventKey="23">
                              <Accordion.Header>
                                {/* School of Business */}
                                Residential Programmes
                              </Accordion.Header>
                              <Accordion.Body>
                                {/* <span>Regular</span> */}
                                <ul>
                                  <li>
                                    <Link to="/academic-programmes/school-of-hrm/pgdm-hrm">
                                      PGDM (HRM)
                                    </Link>{" "}
                                  </li>
                                  <li>
                                    <Link to="/academic-programmes/school-of-business/pgdbm">
                                      PGDM (BM)
                                    </Link>{" "}
                                  </li>
                                  <li>
                                    <Link to="/academic-programmes/school-of-business/pgdgm">
                                      PGDM (GM)
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/academic-programmes/school-of-business/pgdmiev">
                                      PGDM (IEV)
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/lscm">PGDM (LSCM)</Link>
                                  </li>
                                </ul>
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="24">
                              <Accordion.Header>
                                {/* School of HRM */}
                                Virtual / Online Programmes
                              </Accordion.Header>
                              <Accordion.Body>
                                <span>Postgraduate Diploma Programmes</span>
                                <ul>
                                  <li>
                                    <Link to="/xlri-online-learning/pgdbm">
                                      PGDBM{" "}
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/xlri-online-learning/pgdhrm">
                                      PGDM HRM{" "}
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/xlri-online-learning/pgdf">
                                      PGDF{""}
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/pgdhrm-chro">
                                      PGDM (HRM) for Emerging CHROs
                                    </Link>
                                  </li>
                                </ul>
                                <span>Postgraduate Certificate Programmes</span>

                                <ul>
                                  <li>
                                    <Link to="/executive-education/vil/long-term-courses/postgraduate-certificate-in-business-management">
                                      PGCBM
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/executive-education/vil/long-term-courses/postgraduate-certificate-in-human-resource-management">
                                      PGCHRM
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/executive-education/vil/long-term-courses/postgraduate-certificate-in-business-analytics">
                                      PGCBA
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/executive-education/vil/long-term-courses/pgccsrl">
                                      PGCCSRL
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/executive-education/vil/long-term-courses/postgraduate-certificate-in-senior-leadership">
                                      PGCSL
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/executive-education/vil/long-term-courses/executive-diploma-advanced-business-strategies-cxo">
                                      EDABS
                                    </Link>
                                  </li>
                                </ul>
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="25">
                              <Accordion.Header>
                                {/* School of Research */}
                                Fellow Programmes
                              </Accordion.Header>
                              <Accordion.Body>
                                <ul>
                                  <li>
                                    <Link to="/academic-programmes/school-of-research/fpm">
                                      FPM
                                    </Link>{" "}
                                  </li>
                                  <li>
                                    <Link to="/academic-programmes/school-of-research/executive-fpm">
                                      EFPM
                                    </Link>{" "}
                                  </li>
                                </ul>
                              </Accordion.Body>
                            </Accordion.Item>
                            {/* <Accordion.Item eventKey="26">
                              <Accordion.Header>
                                <Link to="/lscm">
                                  XLRI - RBS DOUBLE MASTERS PROGRAMME
                                </Link>
                              </Accordion.Header>
                            </Accordion.Item> */}
                            <Accordion.Item eventKey="27">
                              <Accordion.Header>Admissions</Accordion.Header>
                              <Accordion.Body>
                                <ul>
                                  <li>
                                    <Link>Overview</Link>
                                    <ul>
                                      <li>
                                        <Link to="/academic-programmes/admission-procedure/overview/XAT">
                                          XAT Overview
                                        </Link>
                                      </li>
                                      {/* <li>
                                        <Link to="/academic-programmes/admission-procedure/overview/online-program">
                                          Online Program Overview
                                        </Link>
                                      </li> */}
                                    </ul>
                                  </li>
                                  <li>
                                    <Link to="/academic-programmes/admission-procedure/xat-bulletin">
                                      XAT Bulletin
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/academic-programmes/xat-question-papers">
                                      XAT Question Papers
                                    </Link>
                                  </li>
                                  <li>
                                    {prospectus && (
                                      <Link
                                        to={prospectus?.pdf}
                                        target="_blank"
                                      >
                                        Admission Prospectus{" "}
                                      </Link>
                                    )}
                                  </li>
                                </ul>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        <Link to="/executive-education">
                          Executive Education
                        </Link>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className={styles.innerDropdownMain}>
                          <Accordion>
                            <Accordion.Item eventKey="26">
                              <Accordion.Header>
                                XLRI Executive Education
                              </Accordion.Header>
                              <Accordion.Body>
                                <ul>
                                  <li>
                                    <Link to="/executive-education/edhrm-xlri">
                                      EDHRM
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/executive-education/vil/long-term-courses">
                                      Postgraduate Certificate Programmes
                                    </Link>
                                  </li>
                                  {/* <li>
                                    <Link
                                      to="https://rruxlri.nmediasoft.com/OnlineAdmission/RRURegistration"
                                      target="_blank"
                                    >
                                      Post Graduate Diploma in Corporate
                                      Security Management
                                      <br />
                                      (RRU-XLRI)
                                    </Link>
                                  </li> */}
                                </ul>
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="27">
                              <Accordion.Header>
                                <Link to="/executive-education/vil">
                                  XLEAD - XLRI Leadership Education and
                                  Development
                                </Link>{" "}
                              </Accordion.Header>
                              <Accordion.Body>
                                <ul>
                                  <li>
                                    <Link to="/executive-education/mdp">
                                      Management Development Programmes
                                    </Link>
                                  </li>
                                  <Link to="/executive-education/company-programmes">
                                    In-Company Programmes
                                  </Link>{" "}
                                  <li>
                                    <Link to="/executive-education/consultancy-services">
                                      Consultancy Services
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/executive-education/vil/short-term-courses">
                                      Short Term Courses / eMDP
                                    </Link>
                                  </li>
                                </ul>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        <Link to="/faculty-research">Faculty & Research</Link>
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            <Link to="/faculty-research/research-publications">
                              Research & Publications
                            </Link>{" "}
                          </li>
                          <li>
                            <Link to="/faculty-research/innovation-in-teaching">
                              Faculty Innovation in Teaching
                            </Link>
                          </li>
                          <li>
                            <Link to="/casestudy/home">XLRI Cases</Link>
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="4">
                      <Accordion.Header>
                        <Link to="/resources">Resources</Link>
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            <Link to="/library">Library</Link>{" "}
                          </li>
                          <li>
                            <Link to="/resources/it-infrastructure">
                              Information Technology
                            </Link>
                          </li>
                          <li
                            onClick={() =>
                              redirectToSection(
                                "/resources/behavioural-lab",
                                "behavioural-lab"
                              )
                            }
                          >
                            <Link>Behavioural Lab SideBar</Link>
                          </li>
                          <li
                            onClick={() =>
                              redirectToSection(
                                "/resources/sports-and-recreation",
                                "sports-and-recreation"
                              )
                            }
                          >
                            <Link>Sports and Recreation</Link>
                          </li>
                          <li
                            onClick={() =>
                              redirectToSection(
                                "/resources/food-courts",
                                "food-courts"
                              )
                            }
                          >
                            <Link>Food Courts</Link>
                          </li>
                          <li
                            onClick={() =>
                              redirectToSection(
                                "/resources/auditoriums",
                                "auditoriums"
                              )
                            }
                          >
                            <Link>Auditoriums</Link>
                          </li>
                          <li
                            onClick={() =>
                              redirectToSection(
                                "/resources/faculty-residents",
                                "faculty-residents"
                              )
                            }
                          >
                            <Link>Faculty Residents</Link>
                          </li>
                          <li
                            onClick={() =>
                              redirectToSection(
                                "/resources/students-residents",
                                "students-residents"
                              )
                            }
                          >
                            <Link>Students Residents</Link>
                          </li>
                          <li
                            onClick={() =>
                              redirectToSection(
                                "/resources/guest-accommodation",
                                "guest-accommodation"
                              )
                            }
                          >
                            <Link>Guest Accommodation </Link>
                          </li>
                          <li
                            onClick={() =>
                              redirectToSection(
                                "/resources/gardens-and-parks",
                                "gardens-and-parks"
                              )
                            }
                          >
                            <Link>Gardens and Parks</Link>
                          </li>
                          <li
                            onClick={() =>
                              redirectToSection(
                                "/resources/mobility-with-in-campus",
                                "mobility-with-in-campus"
                              )
                            }
                          >
                            <Link>Mobility with in campus</Link>
                          </li>
                          <li
                            onClick={() =>
                              redirectToSection(
                                "/resources/classroom",
                                "classroom"
                              )
                            }
                          >
                            <Link>Classroom</Link>
                          </li>
                          <li
                            onClick={() =>
                              redirectToSection(
                                "/resources/medical-facilities",
                                "medical-facilities"
                              )
                            }
                          >
                            <Link>Medical Facilities</Link>
                          </li>
                          <li
                            onClick={() =>
                              redirectToSection(
                                "/resources/computer-center",
                                "computer-center"
                              )
                            }
                          >
                            <Link>Computer Center</Link>
                          </li>
                          <li
                            onClick={() =>
                              redirectToSection(
                                "/resources/conference-halls",
                                "conference-halls"
                              )
                            }
                          >
                            <Link>Conference Halls</Link>
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="5">
                      <Accordion.Header>
                        <Link to="/corporate-relations-and-placement">
                          Corporate Relations & Placement
                        </Link>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className={styles.innerDropdownMain}>
                          <Accordion>
                            <Accordion.Item eventKey="28">
                              <Accordion.Header>
                                PGDM (BM) / PGDM (HRM)
                              </Accordion.Header>
                              <Accordion.Body>
                                <ul>
                                  <li>
                                    <Link to="/corporate-relations-and-placement/placement-reports">
                                      {" "}
                                      Placement Reports
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/corporate-relations-and-placement/recruit-at-xlri">
                                      Why Recruit At XLRI?
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/corporate-relations-and-placement/recruitment-activities">
                                      Recruitment Activities
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/corporate-relations-and-placement/summer-internship-placements">
                                      Summer Internship Placements
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/corporate-relations-and-placement/batch-profile">
                                      Batch Profile
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/corporate-relations-and-placement/our-recruiters">
                                      Our Recruiters
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/corporate-relations-and-placement/how-to-create-visibility">
                                      How To Create Visibility?
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/corporate-relations-and-placement/recruitment-faq">
                                      Recruitment FAQ
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/corporate-relations-and-placement/travel-and-accommodation">
                                      Travel and Accommodation
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/corporate-relations-and-placement/placement-office-team-bm-hrm">
                                      Placement Office Team (BM & HRM)
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/corporate-relations-and-placement/student-placement-committee">
                                      Student Placement Committee (BM & HRM)
                                    </Link>
                                  </li>
                                </ul>
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="29">
                              <Accordion.Header>PGDM (GM)</Accordion.Header>
                              <Accordion.Body>
                                <ul>
                                  <li>
                                    <Link to="/student-profile-pgdm-gm">
                                      Student Profile
                                    </Link>{" "}
                                  </li>
                                  <li>
                                    <Link to="/corporate-relations-and-placement/pgdm-gm-batch-profile">
                                      Batch Profile
                                    </Link>
                                  </li>
                                  {prospectusBm && (
                                    <li>
                                      <Link
                                        to="/pgdm-gm-placement-brochure"
                                        target="_blank"
                                      >
                                        {prospectusBm?.brochureName}
                                      </Link>
                                    </li>
                                  )}
                                  <li>
                                    <Link to="/corporate-relations-and-placement/gm-our-recruiters">
                                      Our Recruiters
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/corporate-relations-and-placement/create-visibility-for-your-firm">
                                      Create Visibility for your Firm
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/placement-overview">
                                      Placement Overview
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/career-transformation">
                                      Career Transformation Series
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/placement-office-team-pgdm-gm">
                                      Placement Office Team - PGDM(GM)
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/corporate-relations-and-placement/pgdm-gm-student-committee">
                                      PGDM (GM) Student Committee
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/alumini-speak">
                                      Alumni Speak - PGDM (GM)
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/virtual-internship">
                                      Virtual Internship
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/corporate-relations-and-placement/professional-mentorship-programme">
                                      Professional Mentorship Programme
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/corporate-relations-and-placement/guest-lecture-series">
                                      Guest Lecture Series
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/corporate-relations-and-placement/cxo-series">
                                      CXO Series
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/xl-forum-series">
                                      XL Forum Series
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/annual-alumni-meet">
                                      PGDM (GM) Annual Alumni Meet - Confluence
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/corporate-relations-and-placement/conclaves-seminars-panel-discussion">
                                      Conclaves, Seminars & Panel Discussion
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/corporate-relations-and-placement/faq">
                                      FAQs
                                    </Link>
                                  </li>
                                  {/* <li>
                                    <a href="/">Follow Us on Social Media</a>
                                  </li> */}
                                </ul>
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="30">
                              <Accordion.Header>
                                XOL (PGDBM/ PGDHRM/ PGDF)
                              </Accordion.Header>
                              <Accordion.Body>
                                <ul>
                                  <li>
                                    <Link to="/batch-profile-xol">
                                      Batch Profile
                                    </Link>{" "}
                                  </li>
                                  <li>
                                    <Link to="/guest-lecture-series-xol">
                                      Guest Lecture Series - XOL
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/xol-student-committee">
                                      XOL Student Committee
                                    </Link>{" "}
                                  </li>
                                  <li>
                                    <Link to="/xol-conclave">
                                      XOL Conclaves, Seminars & Panel Discussion
                                    </Link>{" "}
                                  </li>
                                </ul>
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="31">
                              <Accordion.Header>
                                {/* <Link to="/cxo-club">CXO Club</Link> */}
                                <Link to="https://xplore.xlri.ac.in">
                                  XPLORE
                                </Link>
                              </Accordion.Header>
                            </Accordion.Item>
                            <Accordion.Item eventKey="32">
                              <Accordion.Header>PGDM (LSCM)</Accordion.Header>
                              <Accordion.Body>
                                <ul className={styles.commonListingUl}>
                                  <li>
                                    <Link to="/student-profile-pgdm-lscm">
                                      Student Profile
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/corporate-relations-and-placement/pgdm-lscm-batch-profile">
                                      Batch Profile
                                    </Link>
                                  </li>
                                  {prospectusLscm && (
                                    <li>
                                      <Link
                                        to={prospectusLscm?.brochure}
                                        target="_blank"
                                      >
                                        Placement Brochure
                                      </Link>{" "}
                                    </li>
                                  )}
                                  <li>
                                    <Link to="/placement-office-team-pgdm-lscm">
                                      Placement Office Team
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/corporate-relations-and-placement/lscm-our-recruiters">
                                      Our Recruiters
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/corporate-relations-and-placement/lscm-student-placement-committee">
                                      Student Placement Committee (LSCM)
                                    </Link>
                                  </li>
                                </ul>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="6">
                      <Accordion.Header>
                        <Link to="/centres">Centres</Link>
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            <Link to="/centres/centre-for-faces">
                              Fr Arrupe Centre for Ecology and Sustainability
                            </Link>{" "}
                          </li>
                          <li>
                            <Link to="/centres/centre-for-financial-market">
                              Financial Market Centre
                            </Link>
                          </li>
                          <li>
                            <Link to="/centres/centre-for-hrd">
                              Centre for Human Resource Development
                            </Link>
                          </li>
                          <li>
                            <Link to="/centres/centre-for-lscm">
                              Centre for Logistics & SCM
                            </Link>
                          </li>
                          <li>
                            <Link to="/centres/centre-for-peace-and-justice">
                              Centre for Peace and Justice
                            </Link>
                          </li>
                          <li>
                            <Link to="/centres/tata-foundation-in-business-ethics">
                              XLRI JRD Tata Foundation in Business Ethics
                            </Link>
                          </li>
                          <li>
                            <Link to="/centres/centre-for-cgeil">
                              Centre for Gender Equality and Inclusive
                              Leadership
                            </Link>
                          </li>
                          <li>
                            <Link to="/centres/centre-public-policy">
                              Center for Public Policy and Public Affairs
                            </Link>
                          </li>
                          <li>
                            <Link to="/centres/xlces">
                              XLRI Centre for Human Development & Spirituality
                            </Link>
                          </li>
                          <li>
                            <Link to="/casestudy/home">
                              Case Research & Simulation Development Centre
                            </Link>
                          </li>
                          <li>
                            <Link to="/centres/sdcell">
                              XLRI: Skill Development Cell
                            </Link>
                          </li>
                          <li>
                            <Link to="/centres/centre-entrepreneurship">
                              Centre for Entrepreneurship
                            </Link>
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="7">
                      <Accordion.Header>
                        <Link to="/newsroom">News</Link>
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            <Link to="/newsroom/news">XLRI in News</Link>{" "}
                          </li>
                          <li>
                            <Link to="/newsroom/press-releases">
                              Press Releases
                            </Link>
                          </li>
                          <li>
                            <Link to="/newsroom/media-contact">
                              Media Contact
                            </Link>
                          </li>
                          <li>
                            <Link to="/newsroom/events-calendar">
                              Events Calendar
                            </Link>
                          </li>
                          <li>
                            <Link to="/newsroom/gallery">Photo Gallery</Link>
                          </li>
                          <li>
                            <Link to="/newsroom/nirf">NIRF</Link>
                          </li>
                          <li>
                            <Link to="/newsroom/accreditations">
                              Accreditations
                            </Link>
                          </li>
                          <li>
                            <Link to="/newsroom/aicte">AICTE EoA</Link>
                          </li>
                          <li>
                            <Link to="/newsroom/fcra">FCRA</Link>
                          </li>
                          {/* <li>
                            <a href="/">Audited Accounts</a>
                          </li> */}
                          <li>
                            <Link to="/newsroom/important-announcements">
                              Important Announcements
                            </Link>
                          </li>
                          <li>
                            <Link to="/newsroom/magis">Magis</Link>
                          </li>
                          <li>
                            <Link to="/convocation-brochures">
                              Convocation Brochure
                            </Link>
                          </li>
                          <li>
                            <Link to="/xlri-homecoming">XLRI Homecoming</Link>
                          </li>
                          <li>
                            <Link to="/newsroom/celebrating-excellence">
                              Celebrating Excellence
                            </Link>
                          </li>
                          <li>
                            <Link to="/annual-orations">Annual Orations</Link>
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="8">
                      <Accordion.Header>
                        <Link to="/giving-to-xlri">Giving to XLRI</Link>
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            <Link to="/donation">Donation</Link>
                          </li>
                          <li>
                            <Link to="/scholarships">Scholarship Fund</Link>{" "}
                          </li>
                          <li>
                            <Link to="/giving-to-xlri/endowment-fund">
                              Endowment Fund
                            </Link>
                          </li>
                          <li>
                            <Link to="/giving-to-xlri/committee">
                              Committee
                            </Link>
                          </li>
                          <li>
                            <Link to="/giving-to-xlri/future-plans">
                              Future Plans
                            </Link>
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="9">
                      <Accordion.Header>More</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            <Link
                              to="https://xlridelhi.ac.in/#/"
                              target={"_blank"}
                            >
                              XLRI Delhi-NCR
                            </Link>{" "}
                          </li>
                          <li>
                            <Link to="/alumni">Alumni Association</Link>
                          </li>
                          <li>
                            <Link to="/current-student/student-societies">
                              {" "}
                              Current Students
                            </Link>
                          </li>
                          <li>
                            <Link to="/5s">5 s</Link>
                          </li>
                          <li>
                            <Link to="/contact"> Contact us</Link>
                          </li>
                          <li>
                            <Link to="/faq"> FAQs</Link>
                          </li>
                          <li>
                            <Link to="/reaching-xlri">Reaching XLRI</Link>
                          </li>
                          <li>
                            <Link to="/contact-online-grievance">
                              Online Grievance
                            </Link>
                            {/* <Link to="https://acad.xlri.ac.in/grievance/">
                              Online Grievance
                            </Link> */}
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>

                  <div></div>
                </div>
              </div>
            </div>
            <Navbar key={"xl"} expand={"xl"} className="">
              <Container fluid>
                <Navbar.Brand href="/">
                  <div className={styles.logo}>
                    {/* <img src={Assets.logo} alt="" /> */}
                    <img src="https://api.xlri.edu/xlri-75-logo.svg" alt="" />
                  </div>
                </Navbar.Brand>
                <div className={styles.headerRightTopRow}>
                  <ul>
                    <li>
                      <Link to="https://www.aacsb.edu/" target="_blank">
                        {" "}
                        <img src={Assets.topheaderone} alt="" />{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.associationofmbas.com/"
                        target="_blank"
                      >
                        {" "}
                        <img src={Assets.topheadertwo} alt="" />{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="https://www.nbaind.org" target="_blank">
                        {/* <a href="/"> */}{" "}
                        <img src={Assets.topheaderthree} alt="" />{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasRight"
                        aria-controls="offcanvasRightLabel"
                        className={styles.moreBtnmain}
                        to="/"
                      >
                        More
                      </Link>
                    </li>
                    <li onClick={() => setIsVisible(true)}>
                      <a className={styles.searchButton}>
                        <Icon icon="search" size={18} color="#000000" />
                      </a>
                    </li>
                  </ul>

                  <Link
                    className={styles.menuBtnmain}
                    data-bs-toggle="offcanvas"
                    to="#offcanvasExample"
                    role="button"
                    aria-controls="offcanvasExample"
                  >
                    <img src={menuIcon} alt=" Close Button" />
                  </Link>
                </div>
              </Container>
            </Navbar>
          </div>
        </div>

        <div className={styles.HeaderSection}>
          <div className="container">
            <Navbar key={"xl"} expand={"xl"} className="">
              <Container fluid>
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${"xl"}`}>
                  <span></span>
                  <span></span>
                </Navbar.Toggle>

                <Navbar.Offcanvas
                  id={`offcanvasNavbar-expand-${"xl"}`}
                  aria-labelledby={`offcanvasNavbarLabel-expand-${"xl"}`}
                  placement="end"
                >
                  {/* <Offcanvas.Header closeButton> */}
                  {/* <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${'xl'}`}>
                    Offcanvas
                  </Offcanvas.Title> */}
                  {/* </Offcanvas.Header> */}
                  <Offcanvas.Body>
                    <Nav className="justify-content-end flex-grow-1">
                      <div className={styles.headerRightBottomRow}>
                        <ul>
                          <li>
                            <Link to="/about" className="nav-link">
                              About
                            </Link>
                            <div className={styles.aboutUsMegaMenuWrapper}>
                              <div className={styles.aboutUsMegaMenuContents}>
                                <div className="container">
                                  <div className="row">
                                    <div className="col-5">
                                      <div className="row">
                                        <div className="col-6">
                                          <ul
                                            className={styles.commonListingUl}
                                          >
                                            <li>
                                              <Link to="/about/director-message">
                                                Director's Message{" "}
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/about/about-the-director">
                                                About The Director
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/about/heritage">
                                                Heritage
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/about/jesuit-founding-fathers">
                                                Founding Fathers
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/about/our-brand-identity">
                                                Brand XLRI
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/about/vision-mission">
                                                Vision and Mission
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/about/board-of-governors">
                                                Board of Governors
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/about/leadership-administration">
                                                Leadership and Administration
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/about/industry-advisory-councils">
                                                Industry Advisory Council
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/about/programs-and-equivalence">
                                                Programs and Equivalence
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/about/xlri-ranking">
                                                XLRI’s Position & Ranking
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/newsroom/accreditations">
                                                Accreditation
                                              </Link>
                                            </li>
                                          </ul>
                                        </div>
                                        <div className="col-6">
                                          <ul
                                            className={styles.commonListingUl}
                                          >
                                            <li>
                                              <Link to="/about/social-initiatives">
                                                Social Initiatives
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/about/social-engagement-activities">
                                                Social Engagement Activities
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/about/mandatory-disclosure">
                                                Mandatory Disclosure
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/about/careers-opportunities">
                                                Careers Opportunities
                                              </Link>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-2">
                                      <p className="p-16">Faculty </p>
                                      <ul className={styles.commonListingUl}>
                                        <li>
                                          <Link to="/about/full-time-faculty">
                                            Full Time Faculty
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/about/visiting-faculty">
                                            Visiting Faculty
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/about/adjunct-faculty">
                                            Adjunct Faculty
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/about/reserach-faculty-visiting">
                                            Research Faculty (Visiting)
                                          </Link>
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="col-2">
                                      <p className="p-16">Support Staff</p>
                                      <ul className={styles.commonListingUl}>
                                        <li>
                                          <Link to="/support-staff/administrative">
                                            Administrative
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/support-staff/teaching-assistants">
                                            Teaching Assistants
                                          </Link>
                                        </li>
                                        {/* <li>
                                          <Link to="/support-staff/research-associate">
                                            Research Associates
                                          </Link>
                                        </li> */}
                                      </ul>
                                    </div>
                                    <div className="col-3">
                                      <p className="p-16">
                                        International Collaboration
                                      </p>
                                      <ul className={styles.commonListingUl}>
                                        <li>
                                          <Link to="/isep">ISEP</Link>
                                        </li>
                                        {/* <li>
                                          <Link to="/about/social-initiatives">
                                            Social Initiatives
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/about/social-engagement-activities">
                                            Social Engagement Activities
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/">
                                            Mandatory Disclosure
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/aboutus-career">
                                            Careers Opportunities
                                          </Link>
                                        </li> */}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <img
                                  src={Assets.headerAbout}
                                  alt=""
                                  className={styles.aboutmegamenuimg}
                                />
                              </div>
                            </div>
                          </li>
                          <li>
                            <Link
                              to="/academic-programmes"
                              className="nav-link"
                            >
                              Academic Programmes
                            </Link>
                            <div className={styles.aboutUsMegaMenuWrapper}>
                              <div className={styles.aboutUsMegaMenuContents}>
                                <div className="container">
                                  <div className="row">
                                    <div className="col-4">
                                      {/* <p className="p-16">School of Business</p> */}
                                      <p className="p-16">
                                        Residential Programmes
                                      </p>
                                      <div className="row">
                                        <div className="col-6">
                                          {/* <span>Regular</span> */}
                                          <ul
                                            className={styles.commonListingUl}
                                          >
                                            <li>
                                              <Link to="/academic-programmes/school-of-hrm/pgdm-hrm">
                                                PGDM (HRM){" "}
                                                <i>
                                                  Postgraduate Diploma in Human
                                                  Resource Management{" "}
                                                </i>
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/academic-programmes/school-of-business/pgdbm">
                                                PGDM (BM){" "}
                                                <i>
                                                  Postgraduate Diploma in
                                                  Business Management{" "}
                                                </i>
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/academic-programmes/school-of-business/pgdgm">
                                                PGDM (GM){" "}
                                                <i>
                                                  Postgraduate Diploma in
                                                  General Management{" "}
                                                </i>
                                              </Link>
                                            </li>
                                          </ul>
                                        </div>
                                        <div className="col-6">
                                          <span>
                                            {/* <Link to="/xlri-online-learning">
                                              XLRI Online Learning (XOL)
                                            </Link> */}
                                            {/* <Link to="https://xol.xlri.edu/">
                                              XLRI Online Learning (XOL)
                                            </Link>{" "} */}
                                          </span>
                                          <ul
                                            className={styles.commonListingUl}
                                          >
                                            <li>
                                              {/* <Link to="/xlri-online-learning/pgdbm">
                                                PGDBM{" "}
                                                <i>
                                                  Postgraduate Diploma in
                                                  Business Management (Online){" "}
                                                </i>
                                              </Link> */}
                                              {/* <Link to="https://xol.xlri.edu/pgdbm/?">
                                                PGDBM{" "}
                                                <i>
                                                  Postgraduate Diploma in
                                                  Business Management (Online){" "}
                                                </i>
                                              </Link> */}
                                            </li>
                                            <li>
                                              {/* <Link to="/xlri-online-learning/pgdf">
                                                PGDF{" "}
                                                <i>
                                                  Postgraduate Diploma in
                                                  Finance (Online){" "}
                                                </i>
                                              </Link> */}
                                              {/* <Link to="https://xol.xlri.edu/pgdf/?">
                                                PGDF{" "}
                                                <i>
                                                  Postgraduate Diploma in
                                                  Finance (Online){" "}
                                                </i>
                                              </Link> */}
                                            </li>
                                            {/* <li>
                                              <Link to="/xlri-online-learning">
                                                PGCBM - Upcoming{" "}
                                                <i>
                                                  Postgraduate Certificate in
                                                  Business Management (Online){" "}
                                                </i>
                                              </Link>
                                            </li> */}
                                            <li>
                                              <Link to="/academic-programmes/school-of-business/pgdmiev">
                                                PGDM (IEV){" "}
                                                <i>
                                                  Postgraduate Diploma in
                                                  Management(Innovation,
                                                  Entrepreneurship & Venture
                                                  Development){" "}
                                                </i>
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/lscm">
                                                PGDM (LSCM){" "}
                                                <i>
                                                  Postgraduate Diploma in
                                                  Management Logistics & Supply
                                                  Chain Management{" "}
                                                </i>
                                              </Link>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-4">
                                      <p className="p-16">
                                        <Link to="/xlri-online-learning">
                                          Virtual / Online Programmes
                                        </Link>
                                      </p>
                                      <div className="row">
                                        <div className="col-6">
                                          <span className={styles.newListing}>
                                            Postgraduate Diploma Programmes
                                          </span>
                                          <ul className={styles.newListingUl}>
                                            <li className={styles.newListingLi}>
                                              <Link to="/xlri-online-learning/pgdbm">
                                                PGDBM{" "}
                                                <i>
                                                  Postgraduate Diploma in
                                                  Business Management (Online){" "}
                                                </i>
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/xlri-online-learning/pgdhrm">
                                                PGDM HRM{" "}
                                                <i>
                                                  Postgraduate Diploma in Human
                                                  Resource Management (Online){" "}
                                                </i>
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/xlri-online-learning/pgdf">
                                                PGDF{" "}
                                                <i>
                                                  Postgraduate Diploma in
                                                  Finance (Online){" "}
                                                </i>
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/pgdhrm-chro">
                                                PGDM (HRM) for Emerging CHROs
                                              </Link>
                                            </li>
                                          </ul>
                                        </div>
                                        <div className="col-6">
                                          <span className={styles.newListing}>
                                            Postgraduate Certificate Programmes
                                          </span>

                                          <ul className={styles.newListingUl}>
                                            <li>
                                              <Link to="/executive-education/vil/long-term-courses/postgraduate-certificate-in-business-management">
                                                PGCBM
                                                <i>
                                                  Postgraduate Certificate in
                                                  Business Management Programme
                                                </i>
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/executive-education/vil/long-term-courses/postgraduate-certificate-in-human-resource-management">
                                                PGCHRM
                                                <i>
                                                  Postgraduate Certificate in
                                                  Human Resource Management
                                                  Programme
                                                </i>
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/executive-education/vil/long-term-courses/postgraduate-certificate-in-business-analytics">
                                                PGCBA
                                                <i>
                                                  Postgraduate Certificate in
                                                  Business Analytics
                                                </i>
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/executive-education/vil/long-term-courses/pgccsrl">
                                                PGCCSRL
                                                <i>
                                                  Postgraduate Certificate
                                                  Program in CSR Leadership
                                                </i>
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/executive-education/vil/long-term-courses/postgraduate-certificate-in-senior-leadership">
                                                PGCSL
                                                <i>
                                                  Postgraduate Certificate in
                                                  Senior Leadership Programme
                                                </i>
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/executive-education/vil/long-term-courses/executive-diploma-advanced-business-strategies-cxo">
                                                EDABS
                                                <i>
                                                  Executive Diploma in Advanced
                                                  Business Strategies for CXOs
                                                </i>
                                              </Link>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-2">
                                      <p className="p-16">Fellow Programmes</p>
                                      <ul className={styles.commonListingUl}>
                                        <li>
                                          <Link to="/academic-programmes/school-of-research/fpm">
                                            FPM{" "}
                                            <i>
                                              Fellow Programme in Management{" "}
                                            </i>
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/academic-programmes/school-of-research/executive-fpm">
                                            EFPM{" "}
                                            <i>
                                              Executive Fellow Programme in
                                              Management{" "}
                                            </i>
                                          </Link>
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="col-2">
                                      <p className="p-16">Admissions</p>
                                      <ul className={styles.commonListingUl}>
                                        <li>
                                          <Link>Overview</Link>
                                          <ul
                                            className={
                                              styles.newListingUlOverview
                                            }
                                          >
                                            <li>
                                              <Link to="/academic-programmes/admission-procedure/overview/XAT">
                                                XAT Overview
                                              </Link>
                                            </li>
                                            {/* <li>
                                              <Link to="/academic-programmes/admission-procedure/overview/online-program">
                                                Online Program Overview
                                              </Link>
                                            </li> */}
                                          </ul>
                                        </li>
                                        <li>
                                          <Link to="/academic-programmes/admission-procedure/xat-bulletin">
                                            XAT Bulletin <i>XAT Bulletin </i>
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/academic-programmes/xat-question-papers">
                                            XAT Question Papers{" "}
                                            <i>XAT Question Papers </i>
                                          </Link>
                                        </li>
                                        <li>
                                          {prospectus && (
                                            <Link
                                              to={prospectus?.pdf}
                                              target="_blank"
                                            >
                                              Admission Prospectus{" "}
                                            </Link>
                                          )}
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  {/* <div className="row">
                                    <div className="col-12">
                                      <Link to="/lscm">
                                        <p className="p-16">
                                          XLRI - RBS DOUBLE MASTERS PROGRAMME
                                        </p>
                                      </Link>
                                    </div>
                                  </div> */}
                                </div>
                                <img
                                  src={Assets.headerAcademic}
                                  alt=""
                                  className={styles.aboutmegamenuimg}
                                />
                              </div>
                            </div>
                          </li>
                          <li>
                            <Link
                              to="/executive-education"
                              className="nav-link"
                            >
                              Executive Education
                            </Link>
                            <div className={styles.aboutUsMegaMenuWrapper}>
                              <div className={styles.aboutUsMegaMenuContents}>
                                <div className="container">
                                  <div className="row">
                                    <div className="col-4">
                                      <p className="p-16">
                                        XLRI Executive Education
                                      </p>

                                      <ul className={styles.commonListingUl}>
                                        <li>
                                          <Link to="/executive-education/edhrm-xlri">
                                            EDHRM
                                            <i>
                                              Executive Diploma in Human
                                              Resource Management
                                            </i>
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/executive-education/vil/long-term-courses">
                                            Postgraduate Certificate Programmes
                                          </Link>
                                        </li>
                                        {/* <li>
                                          <Link
                                            to="https://rruxlri.nmediasoft.com/OnlineAdmission/RRURegistration"
                                            target="_blank"
                                          >
                                            Post Graduate Diploma in Corporate
                                            Security Management <br />
                                            (RRU-XLRI)
                                          </Link>
                                        </li> */}
                                      </ul>
                                    </div>
                                    <div className="col-4">
                                      <Link to="/executive-education/vil">
                                        <p className="p-16">
                                          {" "}
                                          XLEAD - XLRI Leadership Education and
                                          Development
                                        </p>
                                      </Link>{" "}
                                      <ul className={styles.commonListingUl}>
                                        <li>
                                          <Link to="/executive-education/mdp">
                                            Management Development Programmes
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/executive-education/company-programmes">
                                            In-Company Programmes
                                          </Link>{" "}
                                        </li>
                                        <li>
                                          <Link to="/executive-education/consultancy-services">
                                            Consultancy Services
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/executive-education/vil/short-term-courses">
                                            Short Term Courses / eMDP
                                          </Link>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <img
                                  src={Assets.headerExecutive}
                                  alt=""
                                  className={styles.aboutmegamenuimg}
                                />
                              </div>
                            </div>
                          </li>
                          <li>
                            <Link to="/faculty-research" className="nav-link">
                              Faculty & Research
                            </Link>
                            <div className={styles.aboutUsMegaMenuWrapper}>
                              <div className={styles.aboutUsMegaMenuContents}>
                                <div className="container">
                                  <div className="row">
                                    <div className="col-12">
                                      <ul className={styles.commonListingUl}>
                                        <li>
                                          <Link to="/faculty-research/research-publications">
                                            Research & Publications
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/faculty-research/innovation-in-teaching">
                                            Faculty Innovation in Teaching
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/casestudy/home">
                                            XLRI Cases
                                          </Link>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <img
                                  src={Assets.headerFaculty}
                                  alt=""
                                  className={styles.aboutmegamenuimg}
                                  width={"280px"}
                                />
                              </div>
                            </div>
                          </li>
                          <li
                          //  className={`${scrolledState? styles.displayNone : styles.displayTrue}`}
                          >
                            <Link to="/resources" className="nav-link">
                              Resources
                            </Link>

                            <div className={styles.aboutUsMegaMenuWrapper}>
                              <div className={styles.aboutUsMegaMenuContents}>
                                <div className="container">
                                  <div className="row">
                                    <div className="col-4">
                                      <ul className={styles.commonListingUl}>
                                        <li>
                                          <Link to="/library">Library</Link>
                                        </li>
                                        <li>
                                          <Link to="/resources/it-infrastructure">
                                            Information Technology
                                          </Link>
                                        </li>
                                        <li
                                          onClick={() =>
                                            redirectToSection(
                                              "/resources/behavioural-lab",
                                              "b-lab"
                                            )
                                          }
                                        >
                                          <Link>Behavioural Lab</Link>
                                        </li>
                                        <li
                                          onClick={() =>
                                            redirectToSection(
                                              "/resources/sports-and-recreation",
                                              "sports"
                                            )
                                          }
                                        >
                                          <Link>Sports and Recreation</Link>
                                        </li>
                                        <li
                                          onClick={() =>
                                            redirectToSection(
                                              "/resources/food-courts",
                                              "food-court"
                                            )
                                          }
                                        >
                                          <Link>Food Courts</Link>
                                        </li>
                                      </ul>
                                    </div>

                                    <div className="col-4">
                                      <ul className={styles.commonListingUl}>
                                        <li
                                          onClick={() =>
                                            redirectToSection(
                                              "/resources/auditoriums",
                                              "auditorium"
                                            )
                                          }
                                        >
                                          <Link>Auditoriums</Link>
                                        </li>
                                        <li
                                          onClick={() =>
                                            redirectToSection(
                                              "/resources/faculty-residents",
                                              "faculty"
                                            )
                                          }
                                        >
                                          <Link>Faculty Residents</Link>
                                        </li>
                                        <li
                                          onClick={() =>
                                            redirectToSection(
                                              "/resources/students-residents",
                                              "student-resi"
                                            )
                                          }
                                        >
                                          <Link>Students Residents </Link>
                                        </li>
                                        <li
                                          onClick={() =>
                                            redirectToSection(
                                              "/resources/guest-accommodation",
                                              "guest"
                                            )
                                          }
                                        >
                                          <Link>Guest Accommodation </Link>
                                        </li>
                                        <li
                                          onClick={() =>
                                            redirectToSection(
                                              "/resources/gardens-and-parks",
                                              "garden"
                                            )
                                          }
                                        >
                                          <Link>Gardens and Parks</Link>
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="col-4">
                                      <ul className={styles.commonListingUl}>
                                        <li
                                          onClick={() =>
                                            redirectToSection(
                                              "/resources/mobility-with-in-campus",
                                              "mobility"
                                            )
                                          }
                                        >
                                          <Link>Mobility with in campus</Link>
                                        </li>
                                        <li
                                          onClick={() =>
                                            redirectToSection(
                                              "/resources/classroom",
                                              "classroom"
                                            )
                                          }
                                        >
                                          <Link>Classroom</Link>
                                        </li>
                                        <li
                                          onClick={() =>
                                            redirectToSection(
                                              "/resources/medical-facilities",
                                              "medical"
                                            )
                                          }
                                        >
                                          <Link>Medical Facilities</Link>
                                        </li>
                                        <li
                                          onClick={() =>
                                            redirectToSection(
                                              "/resources/computer-center",
                                              "computer"
                                            )
                                          }
                                        >
                                          <Link>Computer Center</Link>
                                        </li>
                                        <li
                                          onClick={() =>
                                            redirectToSection(
                                              "/resources/conference-halls",
                                              "conference"
                                            )
                                          }
                                        >
                                          <Link>Conference Halls</Link>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <img
                                  src={Assets.headerResource}
                                  alt=""
                                  className={styles.aboutmegamenuimg}
                                />
                              </div>
                            </div>
                          </li>
                          <li
                          //  className={`${scrolledState? styles.displayNone : styles.displayTrue}`}
                          >
                            <Link
                              to="/corporate-relations-and-placement"
                              className="nav-link"
                            >
                              Corporate Relations & Placement
                            </Link>
                            <div className={styles.aboutUsMegaMenuWrapper}>
                              <div className={styles.aboutUsMegaMenuContents}>
                                <div className="container">
                                  <div className="row">
                                    <div className="col-4">
                                      <p className="p-16">
                                        PGDM (BM) / PGDM (HRM)
                                      </p>
                                      <ul className={styles.commonListingUl}>
                                        <li>
                                          <Link to="/corporate-relations-and-placement/placement-reports">
                                            {" "}
                                            Placement Reports
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/corporate-relations-and-placement/recruit-at-xlri">
                                            Why Recruit At XLRI?
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/corporate-relations-and-placement/recruitment-activities">
                                            Recruitment Activities
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/corporate-relations-and-placement/summer-internship-placements">
                                            Summer Internship Placements
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/corporate-relations-and-placement/batch-profile">
                                            Batch Profile
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/corporate-relations-and-placement/our-recruiters">
                                            Our Recruiters
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/corporate-relations-and-placement/how-to-create-visibility">
                                            How To Create Visibility?
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/corporate-relations-and-placement/recruitment-faq">
                                            Recruitment FAQ
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/corporate-relations-and-placement/travel-and-accommodation">
                                            Travel and Accommodation
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/corporate-relations-and-placement/placement-office-team-bm-hrm">
                                            Placement Office Team(BM & HRM)
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/corporate-relations-and-placement/student-placement-committee">
                                            Student Placement Committee (BM &
                                            HRM)
                                          </Link>
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="col-4">
                                      <p className="p-16">PGDM (GM)</p>
                                      <div className="row">
                                        <div className="col-6">
                                          <ul
                                            className={styles.commonListingUl}
                                          >
                                            <li>
                                              <Link to="/student-profile-pgdm-gm">
                                                Student Profile
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/corporate-relations-and-placement/pgdm-gm-batch-profile">
                                                {" "}
                                                Batch Profile
                                              </Link>
                                            </li>
                                            {prospectusBm && (
                                              <li>
                                                <Link
                                                  to="/pgdm-gm-placement-brochure"
                                                  target="_blank"
                                                >
                                                  {prospectusBm?.brochureName}
                                                </Link>
                                              </li>
                                            )}
                                            <li>
                                              <Link to="/corporate-relations-and-placement/gm-our-recruiters">
                                                Our Recruiters
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/corporate-relations-and-placement/create-visibility-for-your-firm">
                                                Create Visibility for your Firm
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/placement-overview">
                                                Placement Overview
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/career-transformation">
                                                Career Transformation Series
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/placement-office-team-pgdm-gm">
                                                Placement Office Team - PGDM(GM)
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/corporate-relations-and-placement/pgdm-gm-student-committee">
                                                PGDM (GM) Student Committee
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/alumini-speak">
                                                Alumni Speak - PGDM (GM)
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/virtual-internship">
                                                Virtual Internship
                                              </Link>
                                            </li>
                                          </ul>
                                        </div>
                                        <div className="col-6">
                                          <ul
                                            className={styles.commonListingUl}
                                          >
                                            <li>
                                              <Link to="/corporate-relations-and-placement/professional-mentorship-programme">
                                                Professional Mentorship
                                                Programme
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/corporate-relations-and-placement/guest-lecture-series">
                                                Guest Lecture Series
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/corporate-relations-and-placement/cxo-series">
                                                CXO Series
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/xl-forum-series">
                                                XL Forum Series
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/annual-alumni-meet">
                                                PGDM (GM) Annual Alumni Meet -
                                                Confluence
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/corporate-relations-and-placement/conclaves-seminars-panel-discussion">
                                                Conclaves, Seminars & Panel
                                                Discussion
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/corporate-relations-and-placement/faq">
                                                FAQs
                                              </Link>
                                            </li>
                                            {/* <li>
                                              <Link to="/">
                                                Follow Us on Social Media
                                              </Link>
                                            </li> */}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-2">
                                      <p className="p-16">
                                        XOL (PGDBM/ PGDHRM/ PGDF)
                                      </p>
                                      <ul className={styles.commonListingUl}>
                                        <li>
                                          <Link to="/batch-profile-xol">
                                            Batch Profile
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/guest-lecture-series-xol">
                                            Guest Lecture Series - XOL
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/xol-student-committee">
                                            XOL Student Committee
                                          </Link>{" "}
                                        </li>
                                        <li>
                                          <Link to="/xol-conclave">
                                            XOL Conclaves, Seminars & Panel
                                            Discussion
                                          </Link>{" "}
                                        </li>
                                      </ul>
                                      <p className="p-16">
                                        {/* <Link to="/cxo-club">CXO Club</Link> */}
                                        <Link to="https://xplore.xlri.ac.in">
                                          XPLORE
                                        </Link>
                                      </p>
                                    </div>
                                    <div className="col-2">
                                      {/* <Link
                                        to="https://drive.google.com/file/d/1Zua25vYu3UotwPbDn0iFML6p9YdkPfAn/view?pli=1"
                                        target="_blank"
                                      > */}
                                      <p className="p-16">PGDM (LSCM)</p>
                                      {/* </Link> */}
                                      <ul className={styles.commonListingUl}>
                                        <li>
                                          <Link to="/student-profile-pgdm-lscm">
                                            Student Profile
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/corporate-relations-and-placement/pgdm-lscm-batch-profile">
                                            Batch Profile
                                          </Link>
                                        </li>
                                        {prospectusLscm && (
                                          <li>
                                            <Link
                                              to={prospectusLscm?.brochure}
                                              target="_blank"
                                            >
                                              Placement Brochure
                                            </Link>{" "}
                                          </li>
                                        )}
                                        <li>
                                          <Link to="/placement-office-team-pgdm-lscm">
                                            Placement Office Team
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/corporate-relations-and-placement/lscm-our-recruiters">
                                            Our Recruiters
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/corporate-relations-and-placement/lscm-student-placement-committee">
                                            Student Placement Committee (LSCM)
                                          </Link>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <img
                                  src={Assets.headerCareer}
                                  alt=""
                                  className={styles.aboutmegamenuimg}
                                />
                              </div>
                            </div>
                          </li>
                          <li
                          // className={`${scrolledState? styles.displayNone : styles.displayTrue}`}
                          >
                            <Link to="/centres" className="nav-link">
                              Centres
                            </Link>
                            <div className={styles.aboutUsMegaMenuWrapper}>
                              <div className={styles.aboutUsMegaMenuContents}>
                                <div className="container">
                                  <div className="row">
                                    <div className="col-4">
                                      <ul className={styles.commonListingUl}>
                                        <li>
                                          <Link to="/centres/centre-for-faces">
                                            Fr Arrupe Centre for Ecology and
                                            Sustainability
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/centres/centre-for-financial-market">
                                            {" "}
                                            Financial Market Centre
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/centres/centre-for-hrd">
                                            Centre for Human Resource
                                            Development
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/centres/centre-for-lscm">
                                            Centre for Logistics & SCM
                                          </Link>
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="col-4">
                                      <ul className={styles.commonListingUl}>
                                        <li>
                                          <Link to="/centres/centre-for-peace-and-justice">
                                            Centre for Peace and Justice
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/centres/tata-foundation-in-business-ethics">
                                            XLRI JRD Tata Foundation in Business
                                            Ethics
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/centres/centre-for-cgeil">
                                            Centre for Gender Equality and
                                            Inclusive Leadership
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/centres/centre-public-policy">
                                            Center for Public Policy and Public
                                            Affairs
                                          </Link>
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="col-4">
                                      <ul className={styles.commonListingUl}>
                                        <li>
                                          <Link to="/centres/xlces">
                                            XLRI Centre for Human Development &
                                            Spirituality
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/casestudy">
                                            Case Research & Simulation
                                            Development Centre
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/centres/sdcell">
                                            XLRI: Skill Development Cell
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/centres/centre-entrepreneurship">
                                            Centre for Entrepreneurship
                                          </Link>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <img
                                  src={Assets.headerCentres}
                                  alt=""
                                  className={styles.aboutmegamenuimg}
                                />
                              </div>
                            </div>
                          </li>
                          <li
                          // className={`${scrolledState? styles.displayNone : styles.displayTrue}`}
                          >
                            <Link to="/newsroom" className="nav-link">
                              News
                            </Link>
                            <div className={styles.aboutUsMegaMenuWrapper}>
                              <div className={styles.aboutUsMegaMenuContents}>
                                <div className="container">
                                  <div className="row">
                                    <div className="col-4">
                                      <ul className={styles.commonListingUl}>
                                        <li>
                                          <Link to="/newsroom/news">
                                            XLRI in News
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/newsroom/press-releases">
                                            Press Releases
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/newsroom/media-contact">
                                            Media Contact
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/newsroom/events-calendar">
                                            Events Calendar
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/newsroom/gallery">
                                            Photo Gallery
                                          </Link>
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="col-4">
                                      <ul className={styles.commonListingUl}>
                                        <li>
                                          <Link to="/newsroom/nirf">NIRF</Link>
                                        </li>
                                        <li>
                                          <Link to="/newsroom/accreditations">
                                            Accreditations
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/newsroom/aicte">
                                            AICTE EoA
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/newsroom/fcra">FCRA</Link>
                                        </li>
                                        {/* <li>
                                          <a href="/">Audited Accounts</a>
                                        </li> */}
                                      </ul>
                                    </div>

                                    <div className="col-4">
                                      <ul className={styles.commonListingUl}>
                                        <li>
                                          <Link to="/newsroom/important-announcements">
                                            Important Announcements
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/newsroom/magis">
                                            Magis
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/convocation-brochures">
                                            Convocation Brochure
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/xlri-homecoming">
                                            XLRI Homecoming
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/newsroom/celebrating-excellence">
                                            Celebrating Excellence
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/annual-orations">
                                            Annual Orations
                                          </Link>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <img
                                  src={Assets.headerNews}
                                  alt=""
                                  className={styles.aboutmegamenuimg}
                                />
                              </div>
                            </div>
                          </li>
                          <li
                          // className={`${scrolledState? styles.displayNone : styles.displayTrue}`}
                          >
                            <Link to="/giving-to-xlri" className="nav-link">
                              Giving to XLRI
                            </Link>
                            <div className={styles.aboutUsMegaMenuWrapper}>
                              <div className={styles.aboutUsMegaMenuContents}>
                                <div className="container">
                                  <div className="row">
                                    <div className="col-12">
                                      <ul className={styles.commonListingUl}>
                                        <li>
                                          <Link to="/donation">Donation</Link>
                                        </li>
                                        <li>
                                          <Link to="/scholarships">
                                            Scholarship Fund
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/giving-to-xlri/endowment-fund">
                                            Endowment Fund
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/giving-to-xlri/committee">
                                            Committee
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/giving-to-xlri/future-plans">
                                            Future Plans
                                          </Link>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <img
                                  src={Assets.headerGivingXlri}
                                  width={"280px"}
                                  alt=""
                                  className={styles.aboutmegamenuimg}
                                />
                              </div>
                            </div>
                          </li>
                          {/* 
                        <li className={`${scrolledState? styles.displayTrue : styles.displayNone}`}>
                            <Link className="nav-link"
                              type="button"
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasExample"
                              aria-controls="offcanvasExampleLabel"

                              href="/">
                             <svg style={{width:'15px',aspectRatio:'1'}} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="Menu / Menu_Duo_LG"> <path id="Vector" d="M3 15H21M3 9H21" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> </g> </g></svg>
                            </Link>

                          </li> */}
                        </ul>
                      </div>
                    </Nav>
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </Container>
            </Navbar>
          </div>
        </div>

        {pathname === "/" && (
          <>
            {isLoading && (
              <div className={styles.marquessMainall}>
                Notifications Loading...
              </div>
            )}
            <>
              {notifications?.length > 0 && (
                <div className={styles.marquessMainall}>
                  <marquee
                    behavior="scroll"
                    onMouseEnter={handleStop}
                    onMouseLeave={handleStart}
                    ref={marqueeRef}
                    scrollamount="5"
                  >
                    <ul className={styles.marqueeNews}>
                      {notifications?.map((item, idx) => (
                        <li key={idx}>
                          <Link to={item?.link ? item?.link : ""}>
                            {item?.notification}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </marquee>
                </div>
              )}
            </>
          </>
        )}
      </header>

      <div className={subStyles.SubheaderWrapper}>
        <div className={subStyles.serchWrapperBodymain}>
          <div
            className="offcanvas offcanvas-top "
            tabIndex="-1"
            id="suboffcanvasTop"
            aria-labelledby="offcanvasTopLabel"
          >
            <div className="offcanvas-body">
              <div className={subStyles.searchWrapperMain}>
                <div className={subStyles.searchWrapperMainDiv2}>
                  <Link to="/">
                    <Icon icon="search" size={18} color="#000000" />
                  </Link>
                </div>
                <input
                  placeholder="Search Here"
                  type="text"
                  value={keyword}
                  ref={searchRef}
                  onChange={(e) => onSearch(e?.target?.value)}
                ></input>
                <div className={subStyles.searchWrapperMainDiv1}>
                  <button
                    type="button"
                    className="btn-close text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>

                {loadingPages ? (
                  <h6>Searching...</h6>
                ) : (
                  <div className={subStyles.searchareaDiv}>
                    {filteredPages ? (
                      <div>
                        <span>Key searches</span>
                        <ul>
                          {filteredPages?.map((item) => (
                            <li>
                              {item.route !== null ? (
                                <Link to={`${item.route}`}>
                                  {item?.highlightedTitle}
                                </Link>
                              ) : null}
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <>Nothing Found!</>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.HeaderSection1}>
          <div className="container">
            <div className={styles.mobileMainMenu}>
              <div
                className="offcanvas offcanvas-end"
                tabIndex="-1"
                id="suboffcanvasExample"
                aria-labelledby="suboffcanvasExampleLabel"
              >
                <div className="offcanvas-header">
                  <button
                    type="button"
                    className="btn-close text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="offcanvas-body">
                  <Accordion>
                    <Accordion.Item eventKey="7">
                      <Accordion.Header>
                        <Link to="/newsroom">News</Link>
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            <Link to="/newsroom/news">XLRI in News</Link>{" "}
                          </li>
                          <li>
                            <Link to="/newsroom/press-releases">
                              Press Releases
                            </Link>
                          </li>
                          <li>
                            <Link to="/newsroom/media-contact">
                              Media Contact
                            </Link>
                          </li>
                          <li>
                            <Link to="/newsroom/events-calendar">
                              Events Calendar
                            </Link>
                          </li>
                          <li>
                            <Link to="/newsroom/gallery">Photo Gallery</Link>
                          </li>
                          <li>
                            <Link to="/newsroom/nirf">NIRF</Link>
                          </li>
                          <li>
                            <Link to="/newsroom/accreditations">
                              Accreditations
                            </Link>
                          </li>
                          <li>
                            <Link to="/newsroom/aicte">AICTE EoA</Link>
                          </li>
                          <li>
                            <Link to="/newsroom/fcra">FCRA</Link>
                          </li>
                          {/* <li>
                            <a href="/">Audited Accounts</a>
                          </li> */}
                          <li>
                            <Link to="/newsroom/important-announcements">
                              Important Announcements
                            </Link>
                          </li>
                          <li>
                            <Link to="/newsroom/magis">Magis</Link>
                          </li>
                          <li>
                            <Link to="/convocation-brochures">
                              Convocation Brochure
                            </Link>
                          </li>
                          <li>
                            <Link to="/xlri-homecoming">XLRI Homecoming</Link>
                          </li>
                          <li>
                            <Link to="/newsroom/celebrating-excellence">
                              Celebrating Excellence
                            </Link>
                          </li>
                          <li>
                            <Link to="/annual-orations">Annual Orations</Link>
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="8">
                      <Accordion.Header>
                        <Link to="/giving-to-xlri">Giving to XLRI</Link>
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            <Link to="/donation">Donation</Link>
                          </li>
                          <li>
                            <Link to="/scholarships">Scholarship Fund</Link>{" "}
                          </li>
                          <li>
                            <Link to="/giving-to-xlri/endowment-fund">
                              Endowment Fund
                            </Link>
                          </li>
                          <li>
                            <Link to="/giving-to-xlri/committee">
                              Committee
                            </Link>
                          </li>
                          <li>
                            <Link to="/giving-to-xlri/future-plans">
                              Future Plans
                            </Link>
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="9">
                      <Accordion.Header>More</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            <Link
                              to="https://xlridelhi.ac.in/#/"
                              target={"_blank"}
                            >
                              XLRI Delhi-NCR
                            </Link>{" "}
                          </li>
                          <li>
                            <Link to="/alumni">Alumni Association</Link>
                          </li>
                          <li>
                            <Link to="/current-student/student-societies">
                              {" "}
                              Current Students
                            </Link>
                          </li>
                          <li>
                            <Link to="/5s">5 s</Link>
                          </li>
                          <li>
                            <Link to="/contact"> Contact us</Link>
                          </li>
                          <li>
                            <Link to="/faq"> FAQs</Link>
                          </li>
                          <li>
                            <Link to="/reaching-xlri">Reaching XLRI</Link>
                          </li>
                          <li>
                            <Link to="/contact-online-grievance">
                              Online Grievance
                            </Link>
                            {/* <Link to="https://acad.xlri.ac.in/grievance/">
                              Online Grievance
                            </Link> */}
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>

                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={subStyles.HeaderSection}>
          <div className="container">
            <Navbar key={"xl"} expand={"xl"} className="">
              <Container fluid>
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${"xl"}`}>
                  <span></span>
                  <span></span>
                </Navbar.Toggle>

                <Navbar.Offcanvas
                  id={`offcanvasNavbar-expand-${"xl"}`}
                  aria-labelledby={`offcanvasNavbarLabel-expand-${"xl"}`}
                  placement="end"
                >
                  {/* <Offcanvas.Header closeButton> */}
                  {/* <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${'xl'}`}>
                    Offcanvas
                  </Offcanvas.Title> */}
                  {/* </Offcanvas.Header> */}
                  <Offcanvas.Body>
                    <Nav className="justify-content-end flex-grow-1">
                      <div className={subStyles.headerRightBottomRow}>
                        <ul>
                          <li>
                            <div className={subStyles.headerLogo}>
                              <img
                                src="https://api.xlri.edu/xlri-75-logo.svg"
                                alt=""
                              />
                            </div>
                          </li>
                          <li>
                            <Link to="/about" className="nav-link">
                              About
                            </Link>
                            <div className={subStyles.aboutUsMegaMenuWrapper}>
                              <div
                                className={subStyles.aboutUsMegaMenuContents}
                              >
                                <div className="container">
                                  <div className="row">
                                    <div className="col-5">
                                      <div className="row">
                                        <div className="col-6">
                                          <ul
                                            className={
                                              subStyles.commonListingUl
                                            }
                                          >
                                            <li>
                                              <Link to="/about/director-message">
                                                Director's Message{" "}
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/about/about-the-director">
                                                About The Director
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/about/heritage">
                                                Heritage
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/about/jesuit-founding-fathers">
                                                Founding Fathers
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/about/our-brand-identity">
                                                Brand XLRI
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/about/vision-mission">
                                                Vision and Mission
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/about/board-of-governors">
                                                Board of Governors
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/about/leadership-administration">
                                                Leadership and Administration
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/about/industry-advisory-councils">
                                                Industry Advisory Council
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/about/programs-and-equivalence">
                                                Programs and Equivalence
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/about/xlri-ranking">
                                                XLRI’s Position & Ranking
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/newsroom/accreditations">
                                                Accreditation
                                              </Link>
                                            </li>
                                          </ul>
                                        </div>
                                        <div className="col-6">
                                          <ul
                                            className={
                                              subStyles.commonListingUl
                                            }
                                          >
                                            <li>
                                              <Link to="/about/social-initiatives">
                                                Social Initiatives
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/about/social-engagement-activities">
                                                Social Engagement Activities
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/about/mandatory-disclosure">
                                                Mandatory Disclosure
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/about/careers-opportunities">
                                                Careers Opportunities
                                              </Link>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-2">
                                      <p className="p-16">Faculty </p>
                                      <ul className={subStyles.commonListingUl}>
                                        <li>
                                          <Link to="/about/full-time-faculty">
                                            Full Time Faculty
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/about/visiting-faculty">
                                            Visiting Faculty
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/about/adjunct-faculty">
                                            Adjunct Faculty
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/about/reserach-faculty-visiting">
                                            Research Faculty (Visiting)
                                          </Link>
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="col-2">
                                      <p className="p-16">Support Staff</p>
                                      <ul className={subStyles.commonListingUl}>
                                        <li>
                                          <Link to="/support-staff/administrative">
                                            Administrative
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/support-staff/teaching-assistants">
                                            Teaching Assistants
                                          </Link>
                                        </li>
                                        {/* <li>
                                          <Link to="/support-staff/research-associate">
                                            Research Associates
                                          </Link>
                                        </li> */}
                                      </ul>
                                    </div>
                                    <div className="col-3">
                                      <p className="p-16">
                                        International Collaboration
                                      </p>
                                      <ul className={subStyles.commonListingUl}>
                                        <li>
                                          <Link to="/isep">ISEP</Link>
                                        </li>
                                        {/* <li>
                                          <Link to="/about/social-initiatives">
                                            Social Initiatives
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/about/social-engagement-activities">
                                            Social Engagement Activities
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/">
                                            Mandatory Disclosure
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/aboutus-career">
                                            Careers Opportunities
                                          </Link>
                                        </li> */}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <img
                                  src={Assets.headerAbout}
                                  alt=""
                                  className={subStyles.aboutmegamenuimg}
                                />
                              </div>
                            </div>
                          </li>
                          <li>
                            <Link
                              to="/academic-programmes"
                              className="nav-link"
                            >
                              Academic Programmes
                            </Link>
                            <div className={subStyles.aboutUsMegaMenuWrapper}>
                              <div
                                className={subStyles.aboutUsMegaMenuContents}
                              >
                                <div className="container">
                                  <div className="row">
                                    <div className="col-4">
                                      {/* <p className="p-16">School of Business</p> */}
                                      <p className="p-16">
                                        Residential Programmes
                                      </p>
                                      <div className="row">
                                        <div className="col-6">
                                          {/* <span>Regular</span> */}
                                          <ul
                                            className={
                                              subStyles.commonListingUl
                                            }
                                          >
                                            <li>
                                              <Link to="/academic-programmes/school-of-hrm/pgdm-hrm">
                                                PGDM (HRM){" "}
                                                <i>
                                                  Postgraduate Diploma in Human
                                                  Resource Management{" "}
                                                </i>
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/academic-programmes/school-of-business/pgdbm">
                                                PGDM (BM){" "}
                                                <i>
                                                  Postgraduate Diploma in
                                                  Business Management{" "}
                                                </i>
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/academic-programmes/school-of-business/pgdgm">
                                                PGDM (GM){" "}
                                                <i>
                                                  Postgraduate Diploma in
                                                  General Management{" "}
                                                </i>
                                              </Link>
                                            </li>
                                          </ul>
                                        </div>
                                        <div className="col-6">
                                          <ul
                                            className={
                                              subStyles.commonListingUl
                                            }
                                          >
                                            <li>
                                              {/* <Link to="/xlri-online-learning/pgdbm">
                                                PGDBM{" "}
                                                <i>
                                                  Postgraduate Diploma in
                                                  Business Management (Online){" "}
                                                </i>
                                              </Link> */}
                                              {/* <Link to="https://xol.xlri.edu/pgdbm/?">
                                                PGDBM{" "}
                                                <i>
                                                  Postgraduate Diploma in
                                                  Business Management (Online){" "}
                                                </i>
                                              </Link> */}
                                            </li>
                                            <li>
                                              {/* <Link to="/xlri-online-learning/pgdf">
                                                PGDF{" "}
                                                <i>
                                                  Postgraduate Diploma in
                                                  Finance (Online){" "}
                                                </i>
                                              </Link> */}
                                              {/* <Link to="https://xol.xlri.edu/pgdf/?">
                                                PGDF{" "}
                                                <i>
                                                  Postgraduate Diploma in
                                                  Finance (Online){" "}
                                                </i>
                                              </Link> */}
                                            </li>
                                            {/* <li>
                                              <Link to="/xlri-online-learning">
                                                PGCBM - Upcoming{" "}
                                                <i>
                                                  Postgraduate Certificate in
                                                  Business Management (Online){" "}
                                                </i>
                                              </Link>
                                            </li> */}
                                            <li>
                                              <Link to="/academic-programmes/school-of-business/pgdmiev">
                                                PGDM (IEV){" "}
                                                <i>
                                                  Postgraduate Diploma in
                                                  Management(Innovation,
                                                  Entrepreneurship & Venture
                                                  Development){" "}
                                                </i>
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/lscm">
                                                PGDM (LSCM){" "}
                                                <i>
                                                  Postgraduate Diploma in
                                                  Management Logistics & Supply
                                                  Chain Management{" "}
                                                </i>
                                              </Link>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-4">
                                      <p className="p-16">
                                        <Link to="/xlri-online-learning">
                                          Virtual / Online Programmes
                                        </Link>
                                      </p>
                                      <div className="row">
                                        <div className="col-6">
                                          <span
                                            className={subStyles.newListing}
                                          >
                                            Postgraduate Diploma Programmes
                                          </span>
                                          <ul
                                            className={subStyles.newListingUl}
                                          >
                                            <li
                                              className={subStyles.newListingLi}
                                            >
                                              <Link to="/xlri-online-learning/pgdbm">
                                                PGDBM{" "}
                                                <i>
                                                  Postgraduate Diploma in
                                                  Business Management (Online){" "}
                                                </i>
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/xlri-online-learning/pgdhrm">
                                                PGDM HRM{" "}
                                                <i>
                                                  Postgraduate Diploma in Human
                                                  Resource Management (Online){" "}
                                                </i>
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/xlri-online-learning/pgdf">
                                                PGDF{" "}
                                                <i>
                                                  Postgraduate Diploma in
                                                  Finance (Online){" "}
                                                </i>
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/pgdhrm-chro">
                                                PGDM (HRM) for Emerging CHROs
                                              </Link>
                                            </li>
                                          </ul>
                                        </div>
                                        <div className="col-6">
                                          <span className={styles.newListing}>
                                            Postgraduate Certificate Programmes
                                          </span>

                                          <ul className={styles.newListingUl}>
                                            <li>
                                              <Link to="/executive-education/vil/long-term-courses/postgraduate-certificate-in-business-management">
                                                PGCBM
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/executive-education/vil/long-term-courses/postgraduate-certificate-in-human-resource-management">
                                                PGCHRM
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/executive-education/vil/long-term-courses/postgraduate-certificate-in-business-analytics">
                                                PGCBA
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/executive-education/vil/long-term-courses/pgccsrl">
                                                PGCCSRL
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/executive-education/vil/long-term-courses/postgraduate-certificate-in-senior-leadership">
                                                PGCSL
                                              </Link>
                                            </li>
                                            <li>
                                              <Link to="/executive-education/vil/long-term-courses/executive-diploma-advanced-business-strategies-cxo">
                                                EDABS
                                              </Link>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-2">
                                      <p className="p-16">Fellow Programmes</p>
                                      <ul className={subStyles.commonListingUl}>
                                        <li>
                                          <Link to="/academic-programmes/school-of-research/fpm">
                                            FPM{" "}
                                            <i>
                                              Fellow Programme in Management{" "}
                                            </i>
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/academic-programmes/school-of-research/executive-fpm">
                                            EFPM{" "}
                                            <i>
                                              Executive Fellow Programme in
                                              Management{" "}
                                            </i>
                                          </Link>
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="col-2">
                                      <p className="p-16">Admissions</p>
                                      <ul className={subStyles.commonListingUl}>
                                        <li>
                                          <Link>
                                            Overview <i>Overview</i>
                                          </Link>
                                          <ul
                                            className={
                                              styles.newListingUlOverview
                                            }
                                          >
                                            <li>
                                              <Link to="/academic-programmes/admission-procedure/overview/XAT">
                                                XAT Overview
                                              </Link>
                                            </li>
                                            {/* <li>
                                              <Link to="/academic-programmes/admission-procedure/overview/online-program">
                                                Online Program Overview
                                              </Link>
                                            </li> */}
                                          </ul>
                                        </li>
                                        <li>
                                          <Link to="/academic-programmes/admission-procedure/xat-bulletin">
                                            XAT Bulletin <i>XAT Bulletin </i>
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/academic-programmes/xat-question-papers">
                                            XAT Question Papers{" "}
                                            <i>XAT Question Papers </i>
                                          </Link>
                                        </li>
                                        <li>
                                          {prospectus && (
                                            <Link
                                              to={prospectus?.pdf}
                                              target="_blank"
                                            >
                                              Admission Prospectus{" "}
                                            </Link>
                                          )}
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <img
                                  src={Assets.headerAcademic}
                                  alt=""
                                  className={subStyles.aboutmegamenuimg}
                                />
                              </div>
                            </div>
                          </li>
                          <li>
                            <Link
                              to="/executive-education"
                              className="nav-link"
                            >
                              Executive Education
                            </Link>
                            <div className={subStyles.aboutUsMegaMenuWrapper}>
                              <div
                                className={subStyles.aboutUsMegaMenuContents}
                              >
                                <div className="container">
                                  <div className="row">
                                    <div className="col-4">
                                      <p className="p-16">
                                        XLRI Executive Education
                                      </p>
                                      <ul className={subStyles.commonListingUl}>
                                        <li>
                                          <Link to="/executive-education/edhrm-xlri">
                                            EDHRM
                                            <i>
                                              Executive Diploma in Human
                                              Resource Management
                                            </i>
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/executive-education/vil/long-term-courses">
                                            Postgraduate Certificate Programmes
                                          </Link>
                                        </li>
                                        {/* <li>
                                          <Link
                                            to="https://rruxlri.nmediasoft.com/OnlineAdmission/RRURegistration"
                                            target="_blank"
                                          >
                                            Post Graduate Diploma in Corporate
                                            Security Management
                                            <br />
                                            (RRU-XLRI)
                                          </Link>
                                        </li> */}
                                      </ul>
                                    </div>
                                    <div className="col-4">
                                      <Link to="/executive-education/vil">
                                        <p className="p-16">
                                          {" "}
                                          XLEAD - XLRI Leadership Education and
                                          Development
                                        </p>
                                      </Link>{" "}
                                      <ul className={subStyles.commonListingUl}>
                                        <li>
                                          <Link to="/executive-education/mdp">
                                            Management Development Programmes
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/executive-education/company-programmes">
                                            In-Company Programmes
                                          </Link>{" "}
                                        </li>
                                        <li>
                                          <Link to="/executive-education/consultancy-services">
                                            Consultancy Services
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/executive-education/vil/short-term-courses">
                                            Short Term Courses / eMDP
                                          </Link>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <img
                                  src={Assets.headerExecutive}
                                  alt=""
                                  className={subStyles.aboutmegamenuimg}
                                />
                              </div>
                            </div>
                          </li>
                          <li>
                            <Link to="/faculty-research" className="nav-link">
                              Faculty & Research
                            </Link>
                            <div className={subStyles.aboutUsMegaMenuWrapper}>
                              <div
                                className={subStyles.aboutUsMegaMenuContents}
                              >
                                <div className="container">
                                  <div className="row">
                                    <div className="col-12">
                                      <ul className={subStyles.commonListingUl}>
                                        <li>
                                          <Link to="/faculty-research/research-publications">
                                            Research & Publications
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/faculty-research/innovation-in-teaching">
                                            Faculty Innovation in Teaching
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/casestudy/home">
                                            XLRI Cases
                                          </Link>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <img
                                  src={Assets.headerFaculty}
                                  alt=""
                                  className={subStyles.aboutmegamenuimg}
                                  width={"280px"}
                                />
                              </div>
                            </div>
                          </li>
                          <li
                          //  className={`${scrolledState? subStyles.displayNone : subStyles.displayTrue}`}
                          >
                            <Link to="/resources" className="nav-link">
                              Resources
                            </Link>

                            <div className={subStyles.aboutUsMegaMenuWrapper}>
                              <div
                                className={subStyles.aboutUsMegaMenuContents}
                              >
                                <div className="container">
                                  <div className="row">
                                    <div className="col-4">
                                      <ul className={subStyles.commonListingUl}>
                                        <li>
                                          <Link to="/library">Library</Link>
                                        </li>
                                        <li>
                                          <Link to="/resources/it-infrastructure">
                                            Information Technology
                                          </Link>
                                        </li>
                                        <li
                                          onClick={() =>
                                            redirectToSection(
                                              "/resources/behavioural-lab",
                                              "b-lab"
                                            )
                                          }
                                        >
                                          <Link>Behavioural Lab</Link>
                                        </li>
                                        <li
                                          onClick={() =>
                                            redirectToSection(
                                              "/resources/sports-and-recreation",
                                              "sports"
                                            )
                                          }
                                        >
                                          <Link>Sports and Recreation</Link>
                                        </li>
                                        <li
                                          onClick={() =>
                                            redirectToSection(
                                              "/resources/food-courts",
                                              "food-court"
                                            )
                                          }
                                        >
                                          <Link>Food Courts</Link>
                                        </li>
                                      </ul>
                                    </div>

                                    <div className="col-4">
                                      <ul className={subStyles.commonListingUl}>
                                        <li
                                          onClick={() =>
                                            redirectToSection(
                                              "/resources/auditoriums",
                                              "auditorium"
                                            )
                                          }
                                        >
                                          <Link>Auditoriums</Link>
                                        </li>
                                        <li
                                          onClick={() =>
                                            redirectToSection(
                                              "/resources/faculty-residents",
                                              "faculty"
                                            )
                                          }
                                        >
                                          <Link>Faculty Residents</Link>
                                        </li>
                                        <li
                                          onClick={() =>
                                            redirectToSection(
                                              "/resources/students-residents",
                                              "student-resi"
                                            )
                                          }
                                        >
                                          <Link>Students Residents </Link>
                                        </li>
                                        <li
                                          onClick={() =>
                                            redirectToSection(
                                              "/resources/guest-accommodation",
                                              "guest"
                                            )
                                          }
                                        >
                                          <Link>Guest Accommodation </Link>
                                        </li>
                                        <li
                                          onClick={() =>
                                            redirectToSection(
                                              "/resources/gardens-and-parks",
                                              "garden"
                                            )
                                          }
                                        >
                                          <Link>Gardens and Parks</Link>
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="col-4">
                                      <ul className={subStyles.commonListingUl}>
                                        <li
                                          onClick={() =>
                                            redirectToSection(
                                              "/resources/mobility-with-in-campus",
                                              "mobility"
                                            )
                                          }
                                        >
                                          <Link>Mobility with in campus</Link>
                                        </li>
                                        <li
                                          onClick={() =>
                                            redirectToSection(
                                              "/resources/classroom",
                                              "classroom"
                                            )
                                          }
                                        >
                                          <Link>Classroom</Link>
                                        </li>
                                        <li
                                          onClick={() =>
                                            redirectToSection(
                                              "/resources/medical-facilities",
                                              "medical"
                                            )
                                          }
                                        >
                                          <Link>Medical Facilities</Link>
                                        </li>
                                        <li
                                          onClick={() =>
                                            redirectToSection(
                                              "/resources/computer-center",
                                              "computer"
                                            )
                                          }
                                        >
                                          <Link>Computer Center</Link>
                                        </li>
                                        <li
                                          onClick={() =>
                                            redirectToSection(
                                              "/resources/conference-halls",
                                              "conference"
                                            )
                                          }
                                        >
                                          <Link>Conference Halls</Link>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <img
                                  src={Assets.headerResource}
                                  alt=""
                                  className={subStyles.aboutmegamenuimg}
                                />
                              </div>
                            </div>
                          </li>
                          <li
                          // className={`${scrolledState? subStyles.displayNone : subStyles.displayTrue}`}
                          >
                            <Link to="/centres" className="nav-link">
                              Centres
                            </Link>
                            <div className={subStyles.aboutUsMegaMenuWrapper}>
                              <div
                                className={subStyles.aboutUsMegaMenuContents}
                              >
                                <div className="container">
                                  <div className="row">
                                    <div className="col-4">
                                      <ul className={subStyles.commonListingUl}>
                                        <li>
                                          <Link to="/centres/centre-for-faces">
                                            Fr Arrupe Centre for Ecology and
                                            Sustainability
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/centres/centre-for-financial-market">
                                            {" "}
                                            Financial Market Centre
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/centres/centre-for-hrd">
                                            Centre for Human Resource
                                            Development
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/centres/centre-for-lscm">
                                            Centre for Logistics & SCM
                                          </Link>
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="col-4">
                                      <ul className={subStyles.commonListingUl}>
                                        <li>
                                          <Link to="/centres/centre-for-peace-and-justice">
                                            Centre for Peace and Justice
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/centres/tata-foundation-in-business-ethics">
                                            XLRI JRD Tata Foundation in Business
                                            Ethics
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/centres/centre-for-cgeil">
                                            Centre for Gender Equality and
                                            Inclusive Leadership
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/centres/centre-public-policy">
                                            Center for Public Policy and Public
                                            Affairs
                                          </Link>
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="col-4">
                                      <ul className={subStyles.commonListingUl}>
                                        <li>
                                          <Link to="/centres/xlces">
                                            XLRI Centre for Human Development &
                                            Spirituality
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/casestudy">
                                            Case Research & Simulation
                                            Development Centre
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/centres/sdcell">
                                            XLRI: Skill Development Cell
                                          </Link>
                                        </li>
                                        <li>
                                          <Link to="/centres/centre-entrepreneurship">
                                            Centre for Entrepreneurship
                                          </Link>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <img
                                  src={Assets.headerCentres}
                                  alt=""
                                  className={subStyles.aboutmegamenuimg}
                                />
                              </div>
                            </div>
                          </li>
                          <li>
                            <Link to="/contact">
                              <button className={subStyles.enquireBtn}>
                                Enquire Now
                              </button>
                            </Link>
                          </li>

                          <li>
                            <Link
                              className="nav-link"
                              type="button"
                              data-bs-toggle="offcanvas"
                              data-bs-target="#suboffcanvasExample"
                              aria-controls="suboffcanvasExampleLabel"
                              to="/"
                            >
                              <svg
                                style={{ width: "15px", aspectRatio: "1" }}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                <g
                                  id="SVGRepo_tracerCarrier"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></g>
                                <g id="SVGRepo_iconCarrier">
                                  {" "}
                                  <g id="Menu / Menu_Duo_LG">
                                    {" "}
                                    <path
                                      id="Vector"
                                      d="M3 15H21M3 9H21"
                                      stroke="#000000"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>{" "}
                                  </g>{" "}
                                </g>
                              </svg>
                            </Link>
                          </li>
                          <li onClick={() => setIsVisible(true)}>
                            <a className={styles.searchButton}>
                              <Icon icon="search" size={18} color="#000000" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </Nav>
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </Container>
            </Navbar>
          </div>
        </div>
      </div>
    </>
  );
}
