export const getToken = () => {
  try {
    let token = localStorage.getItem("x-token");
    let uid = localStorage.getItem("uuid");
    return { token, uid };
  } catch (error) {
    return null;
  }
};

export const setToken = (data) => {
  try {
    if (data) {
      localStorage.setItem("x-token", data.token);
      localStorage.setItem("uuid", data.uid);
    } else {
      localStorage.removeItem("x-token");
      localStorage.removeItem("uuid");
    }
  } catch (error) {
    // console.log(error);
  }
};
